"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef, useId } from 'react';
import { clsx } from 'clsx';
import { SvgIconType } from '../SvgIcon/svg-icon-type.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import { sva } from '../../styled-system/css/sva.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../styled-system/jsx/flex.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import { IconButton } from '../../ui-kit/styled/icon-button.mjs';
import { Text } from '../../ui-kit/styled/text.mjs';
import { SvgIcon } from '../SvgIcon/index.mjs';
import { preventFocusShift } from './utils.mjs';

const dropdownBackButtonRecipe = sva({
  className: "dropdown-back-button",
  slots: ["container", "button", "icon", "title"],
  base: {
    container: {
      gap: "2",
      alignItems: "center",
      px: "1.5",
      py: "1"
    },
    button: {
      h: "6",
      w: "6",
      minW: "unset",
      rounded: "md",
      color: "slate.11",
      _current: {
        bg: "slate.3"
      }
    },
    icon: {
      w: "4",
      h: "4"
    },
    title: { fontWeight: "medium", color: "slate.11", fontSize: "sm" }
  }
});
const BackButton = forwardRef(({ className, children, ...props }, ref) => {
  const { container, button, icon, title } = dropdownBackButtonRecipe();
  const id = useId();
  return /* @__PURE__ */ jsxs(Flex, { className: container, children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        ref,
        variant: "ghost",
        colorPalette: "gray",
        className: clsx(button, className),
        "aria-labelledby": id,
        onMouseDown: preventFocusShift,
        ...props,
        children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.ChevronLeft, className: icon })
      }
    ),
    /* @__PURE__ */ jsx(Text, { as: "span", className: title, id, children })
  ] });
});

export { BackButton };

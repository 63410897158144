import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `await integrationApp
  .flowInstance('{FLOW_INSTANCE_ID}')
  .openEditor()`

export function OpenFlowInstanceEditorCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        FLOW_INSTANCE_ID: CodeParamType.FlowInstanceId,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .flowInstance(parameters.FLOW_INSTANCE_ID)
          .openEditor()
      }}
    />
  )
}

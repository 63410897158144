"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { Truncate } from '../Truncate/index.mjs';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';

function ItemHint({
  value,
  prefix
}) {
  const styles = dropdownRecipe();
  if (!value) return null;
  return /* @__PURE__ */ jsx(
    Truncate,
    {
      className: styles["item-hint"],
      placement: "top-end",
      offset: [0, 0],
      usePortal: true,
      tooltipBuilder: (content) => /* @__PURE__ */ jsxs(Fragment, { children: [
        prefix && `${prefix} `,
        content
      ] }),
      children: value
    }
  );
}

export { ItemHint };

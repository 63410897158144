"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import useEventStopPropagation from '../../helpers/hooks/useEventStopPropagation.mjs';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import { Input } from '../../ui-kit/styled/input.mjs';
import { DROPDOWN_SEARCH_INPUT_ID } from './constants.mjs';

const Search = forwardRef(
  ({ value, onChange, autoFocus, className, placeholder, ...props }, ref) => {
    const styles = dropdownRecipe();
    function handleSearchChange(event) {
      onChange?.(event.target.value);
    }
    const handleEventAndStopPropagation = useEventStopPropagation(true);
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Input,
        {
          className: clsx(styles["search-input"], className),
          role: "searchbox",
          placeholder: placeholder || "Start typing to search...",
          value,
          autoFocus,
          onChange: handleSearchChange,
          onKeyUp: handleEventAndStopPropagation,
          onClick: handleEventAndStopPropagation,
          "data-navigation-emitter-id": DROPDOWN_SEARCH_INPUT_ID,
          ref,
          ...props
        }
      ),
      /* @__PURE__ */ jsx("hr", { className: styles["search-divider"] })
    ] });
  }
);

export { Search };

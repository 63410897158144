import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `const integration = await integrationApp
  .integration('{INTEGRATION_KEY}')
  .connect({
    parameters,
    sameWindow: true,
    redirectUri: window.location.href
  })`

export function IntegrationConnectRedirectCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.integrationKeyWithoutConnection,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .integration(parameters.INTEGRATION_KEY)
          .connect({
            sameWindow: true,
            redirectUri: window.location.href,
          })
      }}
    />
  )
}

import { HTMLAttributes, useEffect } from 'react'
import { SvgIconType, SvgIcon } from '@integration-app/react'
import { Pane, VBox } from '@integration-app/ui/Layout'
import { LogoLoader as UiLogoLoader } from '@integration-app/ui/LogoLoader'
import { Portal } from 'react-portal'

import { css } from 'styled-system/css'
import { HStack, HstackProps } from 'styled-system/jsx'
import { Icon } from 'ui-kit/icon'
import clsx from 'utils/clsx'

import classes from './Loader.module.css'

function setBodyPointerEvents(value) {
  if (typeof window !== 'undefined') {
    const body = window.document.querySelector('body')
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    body.style['pointer-events'] = value
  }
}

export function Backdrop({ children, open = false, ...props }) {
  useEffect(() => {
    setBodyPointerEvents(open ? 'none' : null)

    return function cleanup() {
      setBodyPointerEvents(null)
    }
  }, [open])

  if (!open) {
    return null
  }

  return (
    <Portal>
      <div className={'appBackdrop'} {...props}>
        {children}
      </div>
    </Portal>
  )
}

export function CircularLoader({
  className,
  ...props
}: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      className={clsx(classes.loaderCircular, className)}
      {...props}
      viewBox='22 22 44 44'
    >
      <circle cx='44' cy='44' r='20.2' fill='none' />
    </svg>
  )
}

export function FullHeightCircularLoader() {
  return (
    <div className={'flex flex-col justify-center items-center h-full'}>
      <CircularLoader />
    </div>
  )
}

export function LogoLoader({
  className,
  ...props
}: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <UiLogoLoader className={clsx('appLogoLoader', className)} {...props} />
  )
}

export function BlockLogoLoader({ className }: { className?: string }) {
  return (
    <div
      className={clsx('flex items-center justify-center max-w-5xl', className)}
    >
      <LogoLoader />
    </div>
  )
}

export function EmptyPageLoader() {
  return (
    <div className={'noJsAppBackdrop appBackdrop'}>
      <LogoLoader className={clsx('appLogoLoader_startPage', 'noJsSpinner')} />
    </div>
  )
}

export function PaneLogoLoader() {
  return (
    <VBox className={css({ paddingBlockStart: '13vh' })}>
      <Pane
        stretch
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
        })}
      >
        <LogoLoader />
      </Pane>
    </VBox>
  )
}

export function HStackLoader({ children, ...props }: HstackProps) {
  return (
    <HStack color='fg.muted' textStyle='sm' {...props}>
      <Icon size='sm'>
        <SvgIcon type={SvgIconType.Loader} />
      </Icon>
      {children}
    </HStack>
  )
}

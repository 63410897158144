// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '62bdac9262cb43d4a1f6c2c7303429ae';
const css =`._actionBar_1qbua_1{align-items:center;display:flex;flex-direction:row;justify-content:space-between;width:3rem}._actionBarButton_1qbua_1{--tw-text-opacity:1;align-items:center;color:rgb(105 105 105/var(--tw-text-opacity,1));display:flex;flex-grow:0;flex-shrink:0;height:1.5rem;justify-content:center;width:1.5rem}._actionBarButton_1qbua_1:hover{--tw-text-opacity:1;color:rgb(0 0 0/var(--tw-text-opacity,1))}._actionBarButton_1qbua_1>svg{stroke-width:1.5px;height:1.25rem;width:1.25rem}._actionBarButton__delete_1qbua_1{margin-left:auto}._actionBarButton__delete_1qbua_1>svg{stroke-width:2px;height:1rem;width:1rem}`;
const styles = {
    get ['actionBar']() { injectStyles(key, css, options, undefined, undefined);  return '_actionBar_1qbua_1'; },
    get ['actionBarButton']() { injectStyles(key, css, options, undefined, undefined);  return '_actionBarButton_1qbua_1'; },
    get ['actionBarButton__delete']() { injectStyles(key, css, options, undefined, undefined);  return '_actionBarButton__delete_1qbua_1'; },
    get ['actionBarButtonDelete']() { injectStyles(key, css, options, undefined, undefined);  return '_actionBarButton__delete_1qbua_1'; },
    inject(shadowRoot) { injectStyles(key, css, options, undefined, shadowRoot); }
};
export { styles, css, key };
export default styles;

// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'c19082e6ffe20a8342b471c33947aa74';
const css =`._propertyList_1pmbg_1{display:flex;flex-wrap:wrap;font-size:.813rem;gap:.5rem;line-height:1.4;padding-left:1rem}._propertyList_1pmbg_1:empty{display:none}._propertyWrapper_1pmbg_1{--tw-border-opacity:1;border-color:rgb(221 221 221/var(--tw-border-opacity,1));border-radius:.25rem;border-width:1px;display:inline-flex;gap:.25rem}._property_1pmbg_1{padding:.125rem .5rem}._propertyName_1pmbg_1{--tw-text-opacity:1;color:rgb(170 170 170/var(--tw-text-opacity,1))}._propertyValue_1pmbg_1{--tw-text-opacity:1;color:rgb(0 0 0/var(--tw-text-opacity,1))}._propertyName_1pmbg_1+._propertyValue_1pmbg_1{margin-left:.25rem}._propertiesInModal_1pmbg_1{--tw-border-opacity:1;border-bottom-left-radius:.25rem;border-bottom-right-radius:.25rem;border-color:rgb(221 221 221/var(--tw-border-opacity,1));border-width:0 1px 1px;display:block;font-size:.813rem;line-height:1.4;max-width:calc(100% - .5rem);overflow:auto;width:100%;width:-moz-fit-content;width:fit-content}._propertiesInModal_1pmbg_1:first-child{border-radius:.25rem;border-top-width:1px}`;
const styles = {
    get ['propertyList']() { injectStyles(key, css, options, undefined, undefined);  return '_propertyList_1pmbg_1'; },
    get ['propertyWrapper']() { injectStyles(key, css, options, undefined, undefined);  return '_propertyWrapper_1pmbg_1'; },
    get ['property']() { injectStyles(key, css, options, undefined, undefined);  return '_property_1pmbg_1'; },
    get ['propertyName']() { injectStyles(key, css, options, undefined, undefined);  return '_propertyName_1pmbg_1'; },
    get ['propertyValue']() { injectStyles(key, css, options, undefined, undefined);  return '_propertyValue_1pmbg_1'; },
    get ['propertiesInModal']() { injectStyles(key, css, options, undefined, undefined);  return '_propertiesInModal_1pmbg_1'; },
    inject(shadowRoot) { injectStyles(key, css, options, undefined, shadowRoot); }
};
export { styles, css, key };
export default styles;

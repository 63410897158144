"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { getFormula } from '@integration-app/sdk';
import { useComboBox } from '../../../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../../../context/combobox-dropdown-context.mjs';
import '../../../context/combobox-dropdown-search.mjs';
import '../../../context/combobox-dropdown-stack.mjs';
import { getFormulas } from '../../../../DataBuilder/Formula/index.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../../../helpers/hooks/keyboard-events.mjs';
import '../../../../../styled-system/css/css.mjs';
import '../../../../../styled-system/helpers.mjs';
import { sva } from '../../../../../styled-system/css/sva.mjs';
import '../../../../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../../../../styled-system/jsx/box.mjs';
import { Flex } from '../../../../../styled-system/jsx/flex.mjs';
import { Divider } from '../../../../../styled-system/jsx/divider.mjs';
import '../../../../../styled-system/patterns/visually-hidden.mjs';
import { Button } from '../../../../../ui-kit/button.mjs';

const formulaConfigRecipe = sva({
  className: "formula-config",
  slots: ["container", "form", "save-button"],
  base: {
    container: {
      flexDir: "column",
      p: "1.5",
      gap: "2",
      overflowY: "scroll"
    },
    form: {
      flex: "1",
      overflowX: "auto",
      width: "min(30rem, 70vw)",
      minH: "14"
    },
    "save-button": {
      w: "fit-content",
      h: "fit-content",
      rounded: "xl",
      color: "slate.11",
      px: "2",
      py: "1",
      mt: "1"
    }
  }
});
function DropdownPanelFormulaConfig({
  onOptionSelect
}) {
  const styles = formulaConfigRecipe();
  const formulas = getFormulas();
  const { value, schema, variablesSchema, scopedComboboxId } = useComboBox();
  const { drillUp } = useComboBoxDropdownContext();
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    setLocalValue(value);
  }, [JSON.stringify(value)]);
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_LEFT,
    callback: () => {
      drillUp();
    }
  });
  const formula = getFormula(localValue);
  const formulaSpec = formula?.type && formulas[formula?.type];
  function handleOnChange(newValue) {
    setLocalValue(newValue);
  }
  function handleOnSaveAndClose() {
    onOptionSelect(localValue);
  }
  if (!formulaSpec?.DropdownComponent) {
    return null;
  }
  const FormulaInDropdownPanelComponent = formulaSpec?.DropdownComponent;
  return /* @__PURE__ */ jsxs(Flex, { className: styles.container, children: [
    /* @__PURE__ */ jsx(Box, { className: styles.form, children: /* @__PURE__ */ jsx(
      FormulaInDropdownPanelComponent,
      {
        formula,
        schema,
        variablesSchema,
        onChange: handleOnChange
      }
    ) }),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(
      Button,
      {
        onClick: handleOnSaveAndClose,
        variant: "outline",
        colorPalette: "gray",
        size: "sm",
        className: styles["save-button"],
        children: "Save and Close"
      }
    )
  ] });
}

export { DropdownPanelFormulaConfig };

// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '2d337447398d0ccd7ca04bc695585345';
const css =`._nestedLevel_1rnjr_1>._nestedLevel_1rnjr_1{background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='4' fill='none'%3E%3Cg fill='%23DDD' clip-path='url(%23a)'%3E%3Cpath d='M0 0h1v1H0zm0 3h1v1H0z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h1v4H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");background-position:bottom 0 left .9rem;background-repeat:repeat-y;background-size:1px 4px;padding-left:2rem}._nestedLevelLastChild_1rnjr_1{background-repeat:no-repeat;background-size:1px 2.1rem}._nestedLevel_1rnjr_1 ._nestedLevel_1rnjr_1{flex-grow:1}._nestedLevel_1rnjr_1>._nestedLevel_1rnjr_1>._nestedLevel_1rnjr_1{background-position:bottom 0 left 1.2rem}._nestedLevel_1rnjr_1 [class^=_rowWrapper_]{padding-top:.5rem}`;
const styles = {
    get ['nestedLevel']() { injectStyles(key, css, options, undefined, undefined);  return '_nestedLevel_1rnjr_1'; },
    get ['nestedLevelLastChild']() { injectStyles(key, css, options, undefined, undefined);  return '_nestedLevelLastChild_1rnjr_1'; },
    inject(shadowRoot) { injectStyles(key, css, options, undefined, shadowRoot); }
};
export { styles, css, key };
export default styles;

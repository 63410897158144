// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'b8afaa62c4e7e71a9ef2594f58a54070';
const css =`._popup_a1hvg_1{max-height:min(54rem,100%)!important;max-width:min(35rem,100%);min-height:min(24rem,100%)}._popup_content_a1hvg_1{padding-bottom:1.5rem;padding-left:calc(var(--padding-x, 2.18rem));padding-right:calc(var(--padding-x, 2.18rem));padding-top:1.5rem}._fieldName_a1hvg_1{margin-bottom:1rem}._fieldName_a1hvg_1 span[class^=_sectionFieldName]{--tw-text-opacity:1;color:rgb(0 0 0/var(--tw-text-opacity,1))}._fieldProps_a1hvg_1{display:grid;gap:.5rem}`;
const styles = {
    get ['popup']() { injectStyles(key, css, options, undefined, undefined);  return '_popup_a1hvg_1'; },
    get ['popup_content']() { injectStyles(key, css, options, undefined, undefined);  return '_popup_content_a1hvg_1'; },
    get ['popupContent']() { injectStyles(key, css, options, undefined, undefined);  return '_popup_content_a1hvg_1'; },
    get ['fieldName']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldName_a1hvg_1'; },
    get ['fieldProps']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldProps_a1hvg_1'; },
    inject(shadowRoot) { injectStyles(key, css, options, undefined, shadowRoot); }
};
export { styles, css, key };
export default styles;

/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".FieldListWrapper-module_container__gog9U {\n\n  padding-bottom:\n    0.25rem;\n\n  padding-top:\n    0.75rem;\n\n  cursor:\n    pointer\n}\n.FieldListWrapper-module_container__collapsed__9x5Po {\n\n  margin-top:\n    -2.5rem;\n\n  padding-top:\n    2.5rem;\n\n  position:\n    relative;\n\n  z-index:\n    10;\n\n  background-image:\n    linear-gradient(to bottom, var(--tw-gradient-stops));\n\n  --tw-gradient-from:\n    transparent var(--tw-gradient-from-position);\n\n  --tw-gradient-to:\n    rgb(0 0 0 / 0) var(--tw-gradient-to-position);\n\n  --tw-gradient-stops:\n    var(--tw-gradient-from), var(--tw-gradient-to);\n\n  --tw-gradient-to:\n    #fff var(--tw-gradient-to-position);\n\n  --tw-gradient-stops:\n    var(--tw-gradient-from), var(--tw-gradient-to, transparent) 40%\n}\n.FieldListWrapper-module_button__fkFJa {\n\n  padding-left:\n    0px;\n\n  padding-right:\n    0px;\n\n  padding-top:\n    0.125rem;\n\n  padding-bottom:\n    0.125rem;\n\n  border-bottom-width:\n    1px;\n\n  border-color:\n    transparent;\n\n  display:\n    flex;\n\n  flex-direction:\n    row;\n\n  align-items:\n    center;\n\n  gap:\n    0.25rem;\n\n  --tw-text-opacity:\n    1;\n\n  color:\n    rgb(13 121 194 / var(--tw-text-opacity, 1))\n}\n.FieldListWrapper-module_button__fkFJa svg {\n\n  height:\n    1rem;\n\n  width:\n    1rem\n}\n.FieldListWrapper-module_button__fkFJa:hover,\n.FieldListWrapper-module_container__gog9U:hover button {\n\n  border-style:\n    dashed;\n\n  --tw-border-opacity:\n    1;\n\n  border-color:\n    rgb(13 121 194 / var(--tw-border-opacity, 1))\n}\n";
var classes = {"container":"FieldListWrapper-module_container__gog9U","container__collapsed":"FieldListWrapper-module_container__collapsed__9x5Po","button":"FieldListWrapper-module_button__fkFJa"};
styleInject(css_248z);

export { classes as default };

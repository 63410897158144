import { CodeHighlighterLanguagesEnum } from 'components/CodeHighlighter'

export enum CodeParamType {
  integrationKeyWithoutConnection = 'integrationKeyWithoutConnection',
  IntegrationKey = 'IntegrationKey',
  ConnectionId = 'ConnectionId',
  FieldMappingKey = 'fieldMappingKey',
  FlowKey = 'flowKey',
  FlowInstanceId = 'flowInstanceId',
  ActionKey = 'actionKey',
  DataSourceKey = 'dataSourceKey',
  String = 'string',
  Boolean = 'boolean',
  UDM = 'UDM',
  Object = 'object',
  DataSchema = 'data-schema',
}

export type CodeBlockExtension =
  | 'js'
  | 'jsx'
  | 'ts'
  | 'tsx'
  | 'vue'
  | 'json'
  | 'sh'
  | 'http'
  | 'curl'
  | 'yarn'
  | 'npm'
  | 'html'
  | 'go'
  | 'python'
  | 'java'
  | 'php'
  | 'ruby'

export enum CodeBlockLanguageTitle {
  Javascript = 'Javascript',
  Java = 'Java',
  PHP = 'PHP',
  Go = 'Go',
  Python = 'Python',
  React = 'React',
  Vue = 'Vue',
  yarn = 'yarn',
  npm = 'npm',
  html = 'HTML',
  API = 'API',
  curl = 'curl',
  Bash = 'Bash',
  Ruby = 'Ruby',
}

export type CodeBlockType = Partial<Record<CodeBlockExtension, string>>

export type CodeExampleType = {
  title: CodeBlockLanguageTitle
  language: CodeHighlighterLanguagesEnum
  code: string
}

// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'eb30f8eebe8e84680f9cad0999a46a80';
const css =`._addFieldButtonInnerLevel_sc3v9_1{background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='4' fill='none'%3E%3Cg fill='%23DDD' clip-path='url(%23a)'%3E%3Cpath d='M0 0h1v1H0zm0 3h1v1H0z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h1v4H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");background-position:top 0 left .9rem;background-repeat:repeat-y;background-size:1px 4px;padding-left:2rem}[class^=_nestedLevel_]>[class^=_nestedLevel_] ._addFieldButtonInnerLevel_sc3v9_1{background-position:top 0 left 1.2rem}:root ._addFieldButtonInnerLevel_sc3v9_1 button,:root ._addFieldButtonRootLevel_sc3v9_1 button{--tw-text-opacity:1;color:rgb(13 121 194/var(--tw-text-opacity,1));padding-left:1rem;padding-right:1rem}:is(:root ._addFieldButtonRootLevel_sc3v9_1 button,:root ._addFieldButtonInnerLevel_sc3v9_1 button) svg{height:.75rem;width:.75rem}`;
const styles = {
    get ['addFieldButtonInnerLevel']() { injectStyles(key, css, options, undefined, undefined);  return '_addFieldButtonInnerLevel_sc3v9_1'; },
    get ['addFieldButtonRootLevel']() { injectStyles(key, css, options, undefined, undefined);  return '_addFieldButtonRootLevel_sc3v9_1'; },
    inject(shadowRoot) { injectStyles(key, css, options, undefined, shadowRoot); }
};
export { styles, css, key };
export default styles;

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { SvgIcon } from '../components/SvgIcon/index.mjs';
import { SvgIconType } from '../components/SvgIcon/svg-icon-type.mjs';
import { css } from '../styled-system/css/css.mjs';
import '../styled-system/helpers.mjs';
import { Root, Control, Thumb, Label } from './styled/switch.mjs';
import { SwitchHiddenInput } from '@ark-ui/react/switch';

const Switch = forwardRef(
  (props, ref) => {
    const { children, ...rootProps } = props;
    const { checked, loading } = props;
    return /* @__PURE__ */ jsxs(Root, { ref, ...rootProps, children: [
      /* @__PURE__ */ jsx(Control, { bg: checked ? "green" : "primary", children: loading ? /* @__PURE__ */ jsx(
        SvgIcon,
        {
          type: SvgIconType.Loader,
          className: css({
            w: "4",
            h: "4",
            p: "0.5",
            color: checked ? "white" : "black"
          })
        }
      ) : /* @__PURE__ */ jsx(Thumb, {}) }),
      children && /* @__PURE__ */ jsx(
        Label,
        {
          textStyle: "sm",
          fontWeight: "normal",
          textWrap: "nowrap",
          children
        }
      ),
      /* @__PURE__ */ jsx(SwitchHiddenInput, {})
    ] });
  }
);
Switch.displayName = "Switch";

export { Switch };

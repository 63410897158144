import { ArchiveActionInstanceCodeExample } from './Actions/ArchiveActionInstanceCodeExample'
import { CreateActionInstanceCodeExample } from './Actions/CreateActionInstanceCodeExample'
import { GetActionInstanceCodeExample } from './Actions/GetActionInstanceCodeExample'
import { ListActionInstancesCodeExample } from './Actions/ListActionInstancesCodeExample'
import { PatchActionInstanceCodeExample } from './Actions/PatchActionInstanceCodeExample'
import { ResetActionInstanceCodeExample } from './Actions/ResetActionInstanceCodeExample'
import { RunActionInstanceCodeExample } from './Actions/RunActionInstanceCodeExample'
import { SetupActionInstanceCodeExample } from './Actions/SetupActionInstanceCodeExample'
import { ArchiveConnectionCodeExample } from './Connections/ArchiveConnectionCodeExample'
import { ConnectionParametersUiCodeExample } from './Connections/ConnectionParametersUiCodeExample'
import { ConnectionWidgetUiCodeExample } from './Connections/ConnectionWidgetUiCodeExample'
import { ListConnectionsCodeExample } from './Connections/ListConnectionsCodeExample'
import { OpenNewConnectionAllowMultipleConnectionsCodeExample } from './Connections/OpenNewConnectionAllowMultipleConnectionsCodeExample'
import { OpenNewConnectionCodeExample } from './Connections/OpenNewConnectionCodeExample'
import { GetConnectionDataCollectionCodeExample } from './Connectors/GetConnectionDataCollectionCodeExample'
import { GetConnectorSpecCodeExample } from './Connectors/GetConnectorSpecCodeExample'
import { GetIntegrationDataCollectionCodeExample } from './Connectors/GetIntegrationDataCollectionCodeExample'
import { GetOperationCodeExample } from './Connectors/GetOperationCodeExample'
import { GetOperationsListCodeExample } from './Connectors/GetOperationsListCodeExample'
import { RunOperationCodeExample } from './Connectors/RunOperationCodeExample'
import { DataCollectionCreateCodeExample } from './DataCollections/DataCollectionCreateCodeExample'
import { DataCollectionDeleteCodeExample } from './DataCollections/DataCollectionDeleteCodeExample'
import { DataCollectionFindByIdCodeExample } from './DataCollections/DataCollectionFindByIdCodeExample'
import { DataCollectionListCodeExample } from './DataCollections/DataCollectionListCodeExample'
import { DataCollectionMatchCodeExample } from './DataCollections/DataCollectionMatchCodeExample'
import { DataCollectionSearchCodeExample } from './DataCollections/DataCollectionSearchCodeExample'
import { DataCollectionUpdateCodeExample } from './DataCollections/DataCollectionUpdateCodeExample'
import { ArchiveDataSourceInstanceCodeExample } from './DataSources/ArchiveDataSourceInstanceCodeExample'
import { CreateDataSourceInstanceCodeExample } from './DataSources/CreateDataSourceInstanceCodeExample'
import { DataSourceApiCreateRecordCodeExample } from './DataSources/DataSourceApi/DataSourceApiCreateRecordCodeExample'
import { DataSourceApiDeleteRecordCodeExample } from './DataSources/DataSourceApi/DataSourceApiDeleteRecordCodeExample'
import { DataSourceApiFindRecordByIdCodeExample } from './DataSources/DataSourceApi/DataSourceApiFindRecordByIdCodeExample'
import { DataSourceApiListRecordsCodeExample } from './DataSources/DataSourceApi/DataSourceApiListRecordsCodeExample'
import { DataSourceApiMatchRecordCodeExample } from './DataSources/DataSourceApi/DataSourceApiMatchRecordCodeExample'
import { DataSourceApiSearchRecordsCodeExample } from './DataSources/DataSourceApi/DataSourceApiSearchRecordsCodeExample'
import { DataSourceApiUpdateRecordCodeExample } from './DataSources/DataSourceApi/DataSourceApiUpdateRecordCodeExample'
import { GetDataSourceInstanceCodeExample } from './DataSources/GetDataSourceInstanceCodeExample'
import { GetDataSourceInstanceWithInstanceKeyCodeExample } from './DataSources/GetDataSourceInstanceWithInstanceKeyCodeExample'
import { ListDataSourceInstancesCodeExample } from './DataSources/ListDataSourceInstancesCodeExample'
import { OpenDataSourceInstanceConfigurationCodeExample } from './DataSources/OpenDataSourceInstanceConfigurationCodeExample'
import { PatchDataSourceInstanceCodeExample } from './DataSources/PatchDataSourceInstanceCodeExample'
import { PatchDataSourceInstanceCollectionCodeExample } from './DataSources/PatchDataSourceInstanceCollectionCodeExample'
import { ResetDataSourceInstanceCodeExample } from './DataSources/ResetDataSourceInstanceCodeExample'
import { SetupDataSourceInstanceCodeExample } from './DataSources/SetupDataSourceInstanceCodeExample'
import { ArchiveFieldMappingInstanceCodeExample } from './FieldMappings/ArchiveFieldMappingInstanceCodeExample'
import { CreateFieldMappingInstanceCodeExample } from './FieldMappings/CreateFieldMappingInstanceCodeExample'
import { FieldMappingInstanceUiWithDataFormCodeExample } from './FieldMappings/FieldMappingInstanceUiWithDataFormCodeExample'
import { GetFieldMappingInstanceCodeExample } from './FieldMappings/GetFieldMappingInstanceCodeExample'
import { GetFieldMappingInstanceDataFormCodeExample } from './FieldMappings/GetFieldMappingInstanceDataFormCodeExample'
import { GetFieldMappingInstanceWithInstanceKeyCodeExample } from './FieldMappings/GetFieldMappingInstanceWithInstanceKeyCodeExample'
import { ListFieldMappingInstancesCodeExample } from './FieldMappings/ListFieldMappingInstancesCodeExample'
import { OpenFieldMappingInstanceConfigurationCodeExample } from './FieldMappings/OpenFieldMappingInstanceConfigurationCodeExample'
import { PatchFieldMappingInstanceCodeExample } from './FieldMappings/PatchFieldMappingInstanceCodeExample'
import { ResetFieldMappingInstanceCodeExample } from './FieldMappings/ResetFieldMappingInstanceCodeExample'
import { SetupFieldMappingInstanceCodeExample } from './FieldMappings/SetupFieldMappingInstanceCodeExample'
import { GetFlowRunOutputCodeExample } from './FlowRuns/GetFlowRunOutputCodeExample'
import { GetFlowRunOutputWithNodeKeyCodeExample } from './FlowRuns/GetFlowRunOutputWithNodeKeyCodeExample'
import { ArchiveFlowInstanceCodeExample } from './Flows/ArchiveFlowInstanceCodeExample'
import { CreateFlowInstanceCodeExample } from './Flows/CreateFlowInstanceCodeExample'
import { DisableFlowInstanceCodeExample } from './Flows/DisableFlowInstanceCodeExample'
import { EnableFlowInstanceExample } from './Flows/EnableFlowInstanceExample'
import { GetFlowInstanceCodeExample } from './Flows/GetFlowInstanceCodeExample'
import { GetFlowInstanceWithInstanceKeyCodeExample } from './Flows/GetFlowInstanceWithInstanceKeyCodeExample'
import { ListFlowInstancesCodeExample } from './Flows/ListFlowInstancesCodeExample'
import { OpenFlowInstanceConfigurationCodeExample } from './Flows/OpenFlowInstanceConfigurationCodeExample'
import { OpenFlowInstanceEditorCodeExample } from './Flows/OpenFlowInstanceEditorCodeExample'
import { PatchFlowInstanceCodeExample } from './Flows/PatchFlowInstanceCodeExample'
import { PatchFlowInstanceParametersCodeExample } from './Flows/PatchFlowInstanceParatemersCodeExample'
import { ResetFlowInstanceCodeExample } from './Flows/ResetFlowInstanceCodeExample'
import { RunFlowCodeExample } from './Flows/RunFlowCodeExample'
import { RunFlowInstanceCodeExample } from './Flows/RunFlowInstanceCodeExample'
import { SetupFlowInstanceCodeExample } from './Flows/SetupFlowInstanceCodeExample'
import { ToggleFlowEnabledCodeExample } from './Flows/ToggleFlowEnabledCodeExample'
import { ToggleFlowInstanceEnabledCodeExample } from './Flows/ToggleFlowInstanceEnabledCodeExample'
import { GetIntegrationCodeExample } from './Integrations/GetIntegrationCodeExample'
import { IntegrationConnectCodeExample } from './Integrations/IntegrationConnectCodeExample'
import { IntegrationConnectRedirectCodeExample } from './Integrations/IntegrationConnectRedirectCodeExample'
import { ListDataCollectionsCodeExample } from './Integrations/ListDataCollectionsCodeExample'
import { ListInstancesByIntegrationCodeExample } from './Integrations/ListInstancesByIntegrationCodeExample'
import { ListIntegrationsCodeExample } from './Integrations/ListIntegrationsCodeExample'
import { OpenIntegrationUiCodeExample } from './Integrations/OpenIntegrationUiCodeExample'
import { OpenUiCodeExample } from './OpenUiCodeExample'
import { ComboboxCodeExample } from './ReactSdk/ComboboxCodeExample'
import { DataInputCodeExample } from './ReactSdk/DataInputCodeExample'
import { FieldMappingInstanceExample } from './ReactSdk/FieldMappingInstanceExample'
import { InstallReactSdkCodeExample } from './ReactSdk/InstallReactSdkCodeExample'
import { IntegrationAppProviderCodeExample } from './ReactSdk/IntegrationAppProviderCodeExample'
import { IntegrationAppProviderStyledCodeExample } from './ReactSdk/IntegrationAppProviderStyledCodeExample'
import { OpenUiWithReactSdkCodeExample } from './ReactSdk/OpenUiWithReactSdkCodeExample'
import { ReactSdkFetchTokenCodeExample } from './ReactSdk/ReactSdkFetchTokenCodeExample'
import { InitSdkCodeExample } from './Sdk/InitSdkCodeExample'
import { InitSdkWithCredentialsCodeExample } from './Sdk/InitSdkWithCredentialsCodeExample'
import { InitSdkWithFetchCredentialsCodeExample } from './Sdk/InitSdkWithFetchCredentialsCodeExample'
import { InstallSdkCdnCodeExample } from './Sdk/InstallSdkCdnCodeExample'
import { InstallSdkCodeExample } from './Sdk/InstallSdkCodeExample'
import { SdkFetchTokenCodeExample } from './Sdk/SdkFetchTokenCodeExample'
import { UpdateSdkUserCredentialsCodeExample } from './Sdk/UpdateSdkUserCredentialsCodeExample'
import { CreateAdminJwtCodeExample } from './Token/CreateAdminJwtCodeExample'
import { CreateAsymmetricJwtCodeExample } from './Token/CreateAsymmetricJwtCodeExample'
import { CreateJwtCodeExample } from './Token/CreateJwtCodeExample'
import { GetSelfViaTokenCodeExample } from './Token/GetSelfViaTokenCodeExample'
import { VueBasicSetupCodeExample } from './Vue/VueBasicSetupCodeExample'
import { TriggerGlobalWebhookCodeExample } from './Webhooks/TiggerGlobalWebhookCodeExample'

export const CodeExample = {
  OpenUi: OpenUiCodeExample,

  // integrations
  IntegrationConnect: IntegrationConnectCodeExample,
  IntegrationConnectRedirect: IntegrationConnectRedirectCodeExample,
  ListIntegrations: ListIntegrationsCodeExample,
  OpenIntegrationUi: OpenIntegrationUiCodeExample,
  GetIntegration: GetIntegrationCodeExample,

  // actions
  GetActionInstance: GetActionInstanceCodeExample,
  CreateActionInstance: CreateActionInstanceCodeExample,
  PatchActionInstance: PatchActionInstanceCodeExample,
  ListActionInstances: ListActionInstancesCodeExample,
  RunActionInstance: RunActionInstanceCodeExample,
  SetupActionInstance: SetupActionInstanceCodeExample,
  ResetActionInstance: ResetActionInstanceCodeExample,
  ArchiveActionInstance: ArchiveActionInstanceCodeExample,

  // flows
  GetFlowInstance: GetFlowInstanceCodeExample,
  GetFlowInstanceWithInstanceKey: GetFlowInstanceWithInstanceKeyCodeExample,
  CreateFlowInstance: CreateFlowInstanceCodeExample,
  PatchFlowInstance: PatchFlowInstanceCodeExample,
  ListFlowInstances: ListFlowInstancesCodeExample,
  SetupFlowInstance: SetupFlowInstanceCodeExample,
  ResetFlowInstance: ResetFlowInstanceCodeExample,
  ArchiveFlowInstance: ArchiveFlowInstanceCodeExample,
  RunFlow: RunFlowCodeExample,
  RunFlowInstance: RunFlowInstanceCodeExample,
  ToggleFlowInstanceEnabled: ToggleFlowInstanceEnabledCodeExample,
  ToggleFlowEnabled: ToggleFlowEnabledCodeExample,
  EnableFlowInstance: EnableFlowInstanceExample,
  DisableFlowInstance: DisableFlowInstanceCodeExample,
  OpenFlowInstanceConfiguration: OpenFlowInstanceConfigurationCodeExample,
  OpenFlowInstanceEditor: OpenFlowInstanceEditorCodeExample,
  PatchFlowInstanceParameters: PatchFlowInstanceParametersCodeExample,

  // flow runs
  GetFlowRunOutput: GetFlowRunOutputCodeExample,
  GetFlowRunOutputWithNodeKey: GetFlowRunOutputWithNodeKeyCodeExample,

  // data sources
  GetDataSourceInstance: GetDataSourceInstanceCodeExample,
  CreateDataSourceInstance: CreateDataSourceInstanceCodeExample,
  PatchDataSourceInstance: PatchDataSourceInstanceCodeExample,
  ResetDataSourceInstance: ResetDataSourceInstanceCodeExample,
  SetupDataSourceInstance: SetupDataSourceInstanceCodeExample,
  ArchiveDataSourceInstance: ArchiveDataSourceInstanceCodeExample,
  ListDataSourceInstances: ListDataSourceInstancesCodeExample,
  OpenDataSourceInstanceConfiguration:
    OpenDataSourceInstanceConfigurationCodeExample,
  PatchDataSourceInstanceCollection:
    PatchDataSourceInstanceCollectionCodeExample,
  GetDataSourceInstanceWithInstanceKey:
    GetDataSourceInstanceWithInstanceKeyCodeExample,

  // data source api
  DataSourceApi: {
    ListRecords: DataSourceApiListRecordsCodeExample,
    UpdateRecord: DataSourceApiUpdateRecordCodeExample,
    CreateRecord: DataSourceApiCreateRecordCodeExample,
    DeleteRecord: DataSourceApiDeleteRecordCodeExample,
    MatchRecord: DataSourceApiMatchRecordCodeExample,
    SearchRecords: DataSourceApiSearchRecordsCodeExample,
    FindRecordById: DataSourceApiFindRecordByIdCodeExample,
  },

  // field mappings
  OpenFieldMappingInstanceConfiguration:
    OpenFieldMappingInstanceConfigurationCodeExample,
  ArchiveFieldMappingInstance: ArchiveFieldMappingInstanceCodeExample,
  ResetFieldMappingInstance: ResetFieldMappingInstanceCodeExample,
  SetupFieldMappingInstance: SetupFieldMappingInstanceCodeExample,
  PatchFieldMappingInstance: PatchFieldMappingInstanceCodeExample,
  GetFieldMappingInstance: GetFieldMappingInstanceCodeExample,
  ListFieldMappingInstances: ListFieldMappingInstancesCodeExample,
  CreateFieldMappingInstance: CreateFieldMappingInstanceCodeExample,
  GetFieldMappingInstanceWithInstanceKey:
    GetFieldMappingInstanceWithInstanceKeyCodeExample,
  GetFieldMappingInstanceDataForm: GetFieldMappingInstanceDataFormCodeExample,
  FieldMappingInstanceUiWithDataForm:
    FieldMappingInstanceUiWithDataFormCodeExample,

  // connections
  ListConnections: ListConnectionsCodeExample,
  OpenNewConnection: OpenNewConnectionCodeExample,
  OpenNewConnectionAllowMultipleConnections:
    OpenNewConnectionAllowMultipleConnectionsCodeExample,
  ArchiveConnection: ArchiveConnectionCodeExample,
  ListInstancesByIntegration: ListInstancesByIntegrationCodeExample,
  ConnectionParametersUi: ConnectionParametersUiCodeExample,
  ConnectionWidgetUi: ConnectionWidgetUiCodeExample,

  // data collection methods
  DataCollectionCreate: DataCollectionCreateCodeExample,
  DataCollectionDelete: DataCollectionDeleteCodeExample,
  DataCollectionFindById: DataCollectionFindByIdCodeExample,
  DataCollectionList: DataCollectionListCodeExample,
  DataCollectionMatch: DataCollectionMatchCodeExample,
  DataCollectionUpdate: DataCollectionUpdateCodeExample,
  DataCollectionSearch: DataCollectionSearchCodeExample,

  // connectors
  GetConnectionDataCollection: GetConnectionDataCollectionCodeExample,
  GetIntegrationDataCollection: GetIntegrationDataCollectionCodeExample,
  ListIntegrationDataCollections: ListDataCollectionsCodeExample,
  GetOperation: GetOperationCodeExample,
  GetOperationsList: GetOperationsListCodeExample,
  RunOperation: RunOperationCodeExample,
  GetConnectorSpec: GetConnectorSpecCodeExample,

  // tokens
  CreateJwt: CreateJwtCodeExample,
  CreateAdminJwt: CreateAdminJwtCodeExample,
  CreateAsymmetricJwt: CreateAsymmetricJwtCodeExample,
  GetSelfViaToken: GetSelfViaTokenCodeExample,

  TriggerGlobalWebhook: TriggerGlobalWebhookCodeExample,

  VueBasicSetup: VueBasicSetupCodeExample,
  InitSdk: InitSdkCodeExample,
  InstallSdk: InstallSdkCodeExample,
  InstallSdkCdn: InstallSdkCdnCodeExample,
  SdkFetchToken: SdkFetchTokenCodeExample,
  InitSdkWithCredentials: InitSdkWithCredentialsCodeExample,
  InitSdkWithFetchCredentials: InitSdkWithFetchCredentialsCodeExample,
  UpdateSdkUserCredentials: UpdateSdkUserCredentialsCodeExample,

  InstallReactSdk: InstallReactSdkCodeExample,
  DataInputCode: DataInputCodeExample,
  ComboboxCode: ComboboxCodeExample,
  FieldMappingInstance: FieldMappingInstanceExample,
  IntegrationAppProvider: IntegrationAppProviderCodeExample,
  IntegrationAppProviderStyled: IntegrationAppProviderStyledCodeExample,
  ReactSdkFetchToken: ReactSdkFetchTokenCodeExample,
  OpenUiWithReactSdk: OpenUiWithReactSdkCodeExample,
}

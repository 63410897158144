"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $setSelection } from 'lexical';
import { transformValueToEditorSchema } from '../helpers/transformValueToEditorSchema.mjs';

const getRootParagraphNodes = (editorState) => {
  const paragraph = editorState.toJSON().root.children[0];
  if (paragraph.type !== "paragraph") return [];
  return paragraph.children;
};
function StateSyncPlugin({
  value,
  schema
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.update(() => {
      const currentState = editor.getEditorState();
      const newState = editor.parseEditorState(
        transformValueToEditorSchema(value, schema)
      );
      const currentRootParagraph = JSON.stringify(
        getRootParagraphNodes(currentState)
      );
      const newRootParagraph = JSON.stringify(getRootParagraphNodes(newState));
      if (currentRootParagraph !== newRootParagraph) {
        const selection = $getSelection();
        editor.setEditorState(newState);
        const selectionClone = selection?.clone();
        $setSelection(selectionClone ?? null);
      }
    });
  }, [JSON.stringify(value)]);
  return null;
}

export { StateSyncPlugin };

import { DependencyList, EffectCallback, useEffect, useRef } from 'react'
// swr uses same library
import deepEqual from 'fast-deep-equal'

const isPrimitive = (value: number | string) =>
  ['number', 'string', 'boolean'].includes(typeof value)

const warnDeps = (dependencies: any[]) => {
  if (dependencies.length === 0) {
    console.warn(
      'useDeepEffect should not be used with no dependencies. Use useEffect instead.',
    )
  }

  if (dependencies.every(isPrimitive)) {
    console.warn(
      'useDeepEffect should not be used with primitive values. Use useEffect instead.',
    )
  }
}

const getTriggerDeps = (
  dependencies: any[],
  comparisonFn: (a: any, b: any) => boolean,
): number[] => {
  const ref = useRef<DependencyList>()
  const triggerDeps = useRef<number>(0)

  if (!comparisonFn(dependencies, ref.current)) {
    ref.current = dependencies
    triggerDeps.current = Math.random()
  }

  return [triggerDeps.current]
}

export const useDeepEffect = (
  fn: EffectCallback,
  dependencies: any[] = [],
  comparisonFn = deepEqual,
): void => {
  warnDeps(dependencies)
  return useEffect(fn, getTriggerDeps(dependencies, comparisonFn))
}

/**
 * Memoize a result using deep equality. This hook has two advantages over
 * React.useMemo: it uses deep equality to compare memo keys, and it guarantees
 * that the memo function will only be called if the keys are unequal.
 * React.useMemo cannot be relied on to do this, since it is only a performance
 * optimization (see https://reactjs.org/docs/hooks-reference.html#usememo).
 */
export const useDeepMemo = <TKey, TValue>(
  memoFn: () => TValue,
  key: TKey,
): TValue => {
  const ref = useRef<{ key: TKey; value: TValue }>()

  if (!ref.current || !deepEqual(key, ref.current.key)) {
    ref.current = { key, value: memoFn() }
  }

  return ref.current.value
}

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { SvgIconType } from '../SvgIcon/svg-icon-type.mjs';
import useEventStopPropagation from '../../helpers/hooks/useEventStopPropagation.mjs';
import { Truncate } from '../Truncate/index.mjs';
import { TagConfigButton, TagImage, TagSvgIcon } from './Components.mjs';
import classes from './styles.module.css.mjs';
import { Tooltip } from '../Tooltip/index.mjs';

const GenericTag = forwardRef(function({
  truncate,
  inactive,
  disabled,
  className,
  onClick,
  children,
  ...props
}, ref) {
  return /* @__PURE__ */ jsx(
    "span",
    {
      className: clsx(
        classes.tagGeneric,
        (inactive || disabled) && classes.tagGeneric__disabled,
        className
      ),
      ref,
      onClick: useEventStopPropagation(onClick),
      ...props,
      children: truncate ? /* @__PURE__ */ jsx(Truncate, { children }) : children
    }
  );
});
const TagBasic = forwardRef(function({ className, tooltip, ...props }, ref) {
  if (tooltip) {
    return /* @__PURE__ */ jsx(Tooltip, { tooltip, children: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
      GenericTag,
      {
        ref,
        className: className || classes.tagDefaultColors,
        ...props
      }
    ) }) });
  }
  return /* @__PURE__ */ jsx(
    GenericTag,
    {
      ref,
      className: className || classes.tagDefaultColors,
      ...props
    }
  );
});
const Tag = Object.assign(TagBasic, {
  SvgIcon: TagSvgIcon,
  Image: TagImage,
  ConfigButton: TagConfigButton,
  Lookup: TagLookup
});
function TagLookup({
  children,
  disabled = false,
  onConfigClick,
  configError
}) {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Tag.SvgIcon, { type: SvgIconType.Lookup }),
    children,
    !disabled && /* @__PURE__ */ jsx(Tag.ConfigButton, { onClick: onConfigClick, error: configError })
  ] });
}

export { GenericTag, Tag };

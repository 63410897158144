"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import React, { forwardRef, Children, Fragment as Fragment$1 } from 'react';
import { Button } from '../../../ui-kit/button.mjs';
import { Text } from '../../../ui-kit/styled/text.mjs';
import { IntegrationAvatar } from '../IntegrationAvatar/index.mjs';

const IntegrationItem = forwardRef(({ integration, children, asChild, ...props }, ref) => {
  const hasDirectChild = Boolean(
    children && Children.only(children) && React.isValidElement(children)
  );
  const directDescendant = hasDirectChild ? children : /* @__PURE__ */ jsx(Fragment$1, {});
  return /* @__PURE__ */ jsx(
    Button,
    {
      ref,
      variant: "subtle",
      display: "flex",
      bg: {
        base: "white",
        _hover: "revert-layer"
      },
      justifyContent: "start",
      alignItems: "center",
      gap: "2",
      flexDirection: "column-reverse",
      maxH: "initial",
      h: "initial",
      p: "3",
      pt: "5",
      borderRadius: "md",
      minH: "28",
      whiteSpace: "wrap",
      ...props,
      asChild: asChild && hasDirectChild,
      children: React.cloneElement(directDescendant, {
        children: /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Text, { color: "gray.12", size: "xs", children: integration.name }),
          /* @__PURE__ */ jsx(IntegrationAvatar, { integration })
        ] })
      })
    }
  );
});

export { IntegrationItem };

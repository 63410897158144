"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { getFormula, parseDataLocationPath, DataLocationType, schemaWithTitle } from '@integration-app/sdk';
import { toHeaderCase } from 'js-convert-case';
import useSWR from 'swr';
import { ComboBox } from '../../ComboBox/ComboBox.mjs';
import { DataBuilderForm } from '../../DataBuilder/Form.mjs';
import { useIntegrationApp } from '../../../contexts/integration-app-context.mjs';
import { makeDataField } from '../../DataBuilder/index.mjs';
import { Row, Cell, DrilldownBlockContentWrapper } from '../../DataBuilderUI/index.mjs';
import { useIntegrationElementContext } from '../integration-element-context.mjs';

function DataCollectionSelect({
  value,
  onChange,
  variablesSchema
}) {
  const { integrationId } = useIntegrationElementContext();
  const client = useIntegrationApp();
  let currentKey = value?.collectionKey;
  let currentParameters = value?.collectionParameters;
  const isDynamicLocation = !!getFormula(currentKey);
  if (value?.path && !currentKey) {
    const pointer = parseDataLocationPath(value?.path);
    currentKey = pointer?.key;
    currentParameters = pointer?.parameters;
  }
  const { data: location } = useSWR(
    currentKey && !isDynamicLocation ? `/integrations/${integrationId}/data/${currentKey}` : null,
    (uri) => client.get(uri)
  );
  const { data: locations = [], isLoading } = useSWR(
    `/integrations/${integrationId}/data`,
    (uri) => client.get(uri)
  );
  const filtered = locations.filter(
    ({ type }) => type != DataLocationType.directory
  );
  const options = [];
  filtered.map((location2) => {
    options.push({
      value: location2.key,
      label: location2.name?.toString() ?? toHeaderCase(location2.key)
    });
  });
  function handleKeyChange(key) {
    if (key !== currentKey) {
      return onChange({
        collectionKey: key,
        collectionParameters: void 0,
        // Backward compat
        // Remove it when we migrated all the configs to not use path/defaultPath
        path: void 0,
        defaultPath: void 0
      });
    }
  }
  function handleParametersChange(parameters) {
    const patch = currentKey ? {
      collectionKey: currentKey,
      collectionParameters: parameters
    } : {
      collectionKey: void 0,
      collectionParameters: void 0
    };
    onChange({
      ...patch,
      // Backward compat
      // Remove it when we migrated all the configs to not use path/defaultPath
      path: void 0,
      defaultPath: void 0
    });
  }
  const showParameters = location?.parametersSchema || isDynamicLocation;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Row, { children: [
      /* @__PURE__ */ jsx(Cell.Name, { children: "Data Collection" }),
      /* @__PURE__ */ jsx(Cell.Combobox, { disabled: isLoading, className: "flex-grow", children: /* @__PURE__ */ jsx(
        ComboBox,
        {
          value: currentKey,
          onChange: handleKeyChange,
          disabled: isLoading,
          options: options?.length > 0 ? options : void 0,
          schema: { type: "string" },
          variablesSchema
        }
      ) })
    ] }),
    showParameters && /* @__PURE__ */ jsx("div", { className: "mt-2", children: /* @__PURE__ */ jsx(DrilldownBlockContentWrapper, { children: /* @__PURE__ */ jsx(
      DataBuilderForm,
      {
        onChange: handleParametersChange,
        field: makeDataField({
          schema: schemaWithTitle(
            location ? location.parametersSchema : {
              type: "object",
              additionalProperties: true
            },
            "Parameters"
          ),
          value: currentParameters,
          variablesSchema
        })
      }
    ) }) })
  ] });
}

export { DataCollectionSelect };

import { PropsWithChildren, ReactNode } from 'react'
import { TbExternalLink, TbWindowMaximize } from 'react-icons/tb'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { JsonViewer } from 'components/JsonViewer'
import { Tooltip } from 'components/Tooltip'
import { sva, cx } from 'styled-system/css'
import { Flex, FlexProps, HStack, HstackProps } from 'styled-system/jsx'
import { Badge, BadgeProps } from 'ui-kit/badge'
import { Heading, HeadingProps } from 'ui-kit/heading'
import { Icon } from 'ui-kit/icon'
import { Link } from 'ui-kit/link'
import { Text, TextProps } from 'ui-kit/text'

const errorItemRecipe = sva({
  className: 'errorItem',
  slots: ['list', 'root', 'separator'],
  base: {
    root: {},
    list: {
      '& > .errorItem__separator:not(:first-child)': {
        display: 'block',
      },
    },
    separator: {
      borderTopWidth: 1,
      borderColor: 'border.subtle',
      display: 'none',
    },
  },
})

function List({ children, ...props }: FlexProps) {
  const classes = errorItemRecipe()
  return (
    <Flex className={classes.list} flexDirection={'column'} gap={4} {...props}>
      {children}
    </Flex>
  )
}

function Root({ children, ...props }: FlexProps) {
  const classes = errorItemRecipe()
  return (
    <>
      <hr className={classes.separator} />
      <Flex flexDirection={'column'} gap={3} alignItems={'start'} {...props}>
        {children}
      </Flex>
    </>
  )
}

function Header({ children, ...props }: FlexProps) {
  return (
    <Flex flexDirection={'column'} gap={1} alignItems={'start'} {...props}>
      {children}
    </Flex>
  )
}

function BadgeItem({ children, ...props }: BadgeProps) {
  return (
    <Badge variant={'subtle'} color={'fg.muted'} marginBlockEnd={1} {...props}>
      {children}
    </Badge>
  )
}

function Title({ children, ...props }: HeadingProps) {
  return (
    <Heading
      as={'h2'}
      textStyle={'md'}
      lineHeight={'tight'}
      m={0}
      wordBreak={'break-word'}
      {...props}
    >
      {children}
    </Heading>
  )
}

function Description({ children, ...props }: TextProps) {
  return (
    <Text textStyle={'md'} color={'fg.subtle'} m={0} {...props}>
      {children}
    </Text>
  )
}

const JSONPreviewRecipe = sva({
  className: 'errorItemJsonPreview',
  slots: ['root', 'button'],
  base: {
    root: {
      color: 'fg.muted',
      borderColor: 'border.muted',
      borderWidth: 1,
      borderRadius: 'l2',
      paddingInline: 2,
      paddingBlock: 1,
      width: 'full',
      gap: 2,
    },
    button: {
      display: 'none',
    },
  },
  variants: {
    variant: {
      clickable: {
        root: {
          position: 'relative',
          _focusWithin: {
            outline: '2px solid',
          },
          _hover: {
            backgroundColor: 'bg.subtle',
          },
        },
        button: {
          display: 'block',
          position: 'absolute',
          inset: 0,
          zIndex: 'tooltip',
          opacity: 0,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      },
      viewer: {
        root: {
          width: 'full',
          overflowX: 'auto',
          paddingInlineStart: 5,
        },
      },
    },
  },
})

type JSONPreviewGenericProps = Omit<HstackProps, 'onClick'> & {
  json: any
}
type JSONPreviewClickableProps = JSONPreviewGenericProps & {
  onClick: () => void
  tooltip: ReactNode
}
type JSONPreviewNonClickableProps = JSONPreviewGenericProps & {
  onClick?: never
  tooltip?: never
}
type JSONPreviewProps = JSONPreviewClickableProps | JSONPreviewNonClickableProps

function JSONPreview({
  json,
  onClick,
  className,
  tooltip,
  ...props
}: JSONPreviewProps) {
  const JSONPreviewRecipeClasses = JSONPreviewRecipe({
    variant: onClick ? 'clickable' : undefined,
  })
  const previewJson = JSON.stringify(json, null, '').slice(0, 65)

  return (
    <HStack className={cx(JSONPreviewRecipeClasses.root, className)} {...props}>
      {onClick && (
        <Tooltip disabled={!onClick} tooltip={tooltip} openDelay={200}>
          <button className={JSONPreviewRecipeClasses.button} onClick={onClick}>
            <Text srOnly>{tooltip}</Text>
          </button>
        </Tooltip>
      )}
      <Text flexGrow={1} as={'pre'} maxWidth={'full'} truncate>
        <Text as={'code'} textStyle={'sm'} lineHeight={2}>
          {previewJson}
        </Text>
      </Text>
      {onClick && (
        <Icon size={'sm'}>
          <TbWindowMaximize />
        </Icon>
      )}
    </HStack>
  )
}
function JSONViewer({
  json,
  className,
  ...props
}: HstackProps & { json: any }) {
  const JSONPreviewRecipeClasses = JSONPreviewRecipe({ variant: 'viewer' })

  return (
    <HStack className={cx(JSONPreviewRecipeClasses.root, className)} {...props}>
      <JsonViewer json={json} collapsed={false} />
    </HStack>
  )
}

function DocLink({
  children,
  ...props
}: PropsWithChildren<Omit<NavLinkProps, 'children'>>) {
  return (
    <Link
      asChild
      textStyle={'sm'}
      color={'primary.default'}
      colorPalette={'primary'}
      fontWeight={'bold'}
      display={'inline-flex'}
      alignItems={'center'}
    >
      <NavLink {...props}>
        {children}
        <Icon size={'sm'}>
          <TbExternalLink />
        </Icon>
      </NavLink>
    </Link>
  )
}

const ErrorItem = {
  List,
  Root,
  Header,
  Badge: BadgeItem,
  Title,
  Description,
  JSONPreview,
  JSONViewer,
  DocLink,
}

export { ErrorItem }

import { useLocation, useNavigate } from 'react-router-dom'

export function useNavigateWithNativeBehavior() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Open in new tab if cmd/ctrl key is pressed
  // Otherwise, navigate normally
  return function navigateWithNativeBehavior(
    href: string,
    e?: React.MouseEvent,
  ) {
    if (e?.metaKey || e?.ctrlKey) {
      const location = window.location
      window.open(location.pathname.replace(pathname, href), '_blank')
    } else {
      return navigate(href)
    }
  }
}

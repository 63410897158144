"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { createContext, useContext } from 'react';

const DropdownItemAriaContext = createContext({});
const DropdownItemAriaContextProvider = DropdownItemAriaContext.Provider;
const useDropdownItemAriaContext = () => {
  return useContext(DropdownItemAriaContext);
};

export { DropdownItemAriaContextProvider, useDropdownItemAriaContext };

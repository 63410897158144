import { useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { Input, InputProps } from 'ui-kit/input'

export interface DebouncedInputProps extends Omit<InputProps, 'onChange'> {
  value: string | undefined
  onChange: (value: string) => void
  debounce?: number
}

export function DebouncedInput({
  value,
  onChange,
  debounce = 300,
  ...props
}: DebouncedInputProps) {
  const [localValue, setLocalValue] = useState(value)
  const debouncedOnChange = useDebounceCallback(onChange, debounce)

  function handleOnChange(newValue) {
    setLocalValue(newValue)
    debouncedOnChange(newValue)
  }

  return (
    <Input
      value={localValue}
      onChange={(e) => handleOnChange(e.target.value)}
      {...props}
    />
  )
}

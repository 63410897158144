"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { useComboBox } from '../../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../../context/combobox-dropdown-context.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../../helpers/hooks/keyboard-events.mjs';
import { useNavigationContext } from './NavigationContext.mjs';

const useKeyboardBinding = ({
  options,
  fallbackOptions,
  onEnter = () => {
  }
}) => {
  const { scopedComboboxId } = useComboBox();
  const { drillUp } = useComboBoxDropdownContext();
  const { currentItem, moveToNextItem, moveToPreviousItem } = useNavigationContext(options, fallbackOptions);
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_DOWN,
    callback: moveToPreviousItem
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_UP,
    callback: moveToNextItem
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_LEFT,
    callback: drillUp
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_RIGHT,
    callback: () => {
      if (currentItem) {
        onEnter(currentItem);
      }
    }
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_SELECT,
    callback: () => {
      if (currentItem) {
        onEnter(currentItem);
      }
    }
  });
};

export { useKeyboardBinding };

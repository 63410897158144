"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

function getRefElement(ref) {
  if (!ref) return null;
  if (isRefObject(ref)) {
    return ref.current ?? null;
  }
  return ref;
}
function isRefObject(ref) {
  return !!ref ? typeof ref === "object" && "current" in ref : false;
}

export { getRefElement };

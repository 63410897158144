import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IntegrationAppClientProvider } from '@integration-app/react'
import {
  Customer,
  EngineWorkspace,
  IntegrationAppClient,
  Workspace,
  WorkspaceUser,
  DataSchema,
} from '@integration-app/sdk'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import * as jose from 'jose'
import { useRouter } from 'next/router'
import { TbArrowRight } from 'react-icons/tb'

import { EmptyPageLoader } from 'components/Loader'
import { ENV_CONFIG } from 'config/env'
import useApi from 'hooks/useApi'
import { useDeepMemo } from 'hooks/useDeepEffect'
import { Flex } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'
import { Heading } from 'ui-kit/heading'
import { Icon } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'

import useAuth from '../../contexts/auth'
import { Combobox, SelectItemType } from '../Select'

interface IWorkspaceContext {
  loading: boolean

  workspace: Workspace
  workspaces: Workspace[]

  patchWorkspace(data: Partial<Workspace>): Promise<void>
  changeWorkspace(newWorkspaceId: string | null, sufix?: string): Promise<void>

  engineApp: EngineWorkspace
  patchEngineApp(data: Record<string, any>): Promise<void>
  archiveApp(): Promise<void>

  engineAdminClient: IntegrationAppClient
  engineAdminFetcher(url: string): Promise<any>
  engineAdminBinaryFetcher(url: string): Promise<any>

  testCustomerClient: IntegrationAppClient
  testCustomerFetcher(url: string): Promise<any>

  testCustomerAccessToken: string
  makeAccessToken(internalId: string): Promise<string | undefined>

  testCustomer: Customer
  patchTestCustomer(data: Partial<Customer>): Promise<void>

  getUserSchema(): DataSchema
}

const WorkspaceContext = createContext<IWorkspaceContext>({
  loading: false,
  workspace: {},
  workspaces: [],

  patchWorkspace: async () => {},
  changeWorkspace: async () => {},

  engineApp: {},
  patchEngineApp: async () => {},
  archiveApp: async () => {},

  engineAdminClient: {},
  engineAdminFetcher: async () => {},
  engineAdminBinaryFetcher: async () => {},

  testCustomerClient: {},
  testCustomerFetcher: async () => {},

  testCustomerAccessToken: '',
  makeAccessToken: async () => '',

  testCustomer: {} as Customer,
  patchTestCustomer: async () => {},
  getUserSchema: () => {},
} as any)

export const WorkspaceProvider = ({ wsId, children }) => {
  const router = useRouter()
  const { self, mutateSelf } = useAuth()
  const { apiClient, setImpersonationToken } = useApi()

  const isAdmin: boolean = self.user?.isAdmin ?? false
  const defaultWorkspaceId: string | undefined = self.user?.defaultWorkspaceId
  const workspace: Workspace | undefined = self?.workspace
  const workspaceUser: WorkspaceUser | undefined = self?.workspaceUser
  const workspaces: Workspace[] = self?.workspaces ?? []

  const engineApp = self?.engineApp
  const testCustomer: Customer = self?.engineTestUser

  const [testCustomerAccessToken, setTestCustomerAccessToken] = useState<
    string | undefined
  >()
  const [selectedWsItem, setSelectedWsItem] = useState<
    SelectItemType | undefined
  >(convertWorkspaceToComboboxItem(workspaces[0]))

  // NOTE: this state is used to prevent rendering workspace before we checked access with checkWorkspaceAccess function.
  // We need this because if we try to route.push when react-router component is rendering it can cancel the next.js redirect
  const [checked, setChecked] = useState(false)

  const engineAdminClient = workspace?.engineAccessToken
    ? new IntegrationAppClient({
        apiUri: ENV_CONFIG.ENGINE_API_URI,
        uiUri: ENV_CONFIG.ENGINE_UI_URI,
        token: workspace.engineAccessToken,
      })
    : undefined

  async function impersonateWorkspaceOwner(workspaceId: string) {
    const { data } = await apiClient.get(
      `console-admin/impersonate-workspace-owner/${workspaceId}`,
    )
    const { token } = data

    setImpersonationToken(token)
    window.location.reload()
  }

  const engineAdminFetcher = (url: string) =>
    engineAdminClient?.get(url) ?? new Promise((resolve) => resolve(undefined))

  const engineAdminBinaryFetcher = (url: string): Promise<any> => {
    return (
      engineAdminClient?.get(url, {}, { responseType: 'arraybuffer' }) ??
      new Promise((resolve) => resolve(undefined))
    )
  }

  async function makeAccessToken(internalId: string) {
    if (!workspace) return undefined

    return new jose.SignJWT({ id: internalId })
      .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
      .setIssuer(workspace.key)
      .setExpirationTime('365d')
      .sign(new TextEncoder().encode(workspace.secret))
  }

  const testCustomerClient = useMemo(() => {
    if (!testCustomerAccessToken) return undefined

    return new IntegrationAppClient({
      apiUri: ENV_CONFIG.ENGINE_API_URI,
      uiUri: ENV_CONFIG.ENGINE_UI_URI,
      token: testCustomerAccessToken,
    })
  }, [testCustomerAccessToken])

  const testCustomerFetcher = (url: string) =>
    testCustomerClient?.get(url) ?? new Promise((resolve) => resolve(undefined))

  const selfLoading = !self

  async function patchWorkspace(data: Partial<Workspace>) {
    if (!workspace) return

    await mutateSelf(
      {
        ...self,
        workspace: {
          ...self.workspace,
          ...data,
        },
      },
      false,
    )
    await apiClient.patch(`/workspaces/${workspace.id}`, data)
    await mutateSelf()
  }

  async function archiveApp() {
    if (!workspace) return

    await apiClient.delete(`/workspaces/${workspace.id}`)
    await mutateSelf()
  }

  async function patchTestCustomer(data: Partial<Customer>) {
    if (!data || !workspace || !workspaceUser) return

    const newCustomerData = {
      ...testCustomer,
      ...data,
    }

    // local update
    await mutateSelf(
      {
        ...self,
        engineTestUser: newCustomerData,
      },
      false,
    )

    await engineAdminClient?.customer(testCustomer.id).put(newCustomerData)

    await mutateSelf()
  }

  const debouncedPutEngineApp = useCallback(
    AwesomeDebouncePromise(async (data) => {
      await engineAdminClient?.put(`apps/${workspace?.key}`, data)
    }, 1000),
    [],
  )

  async function patchEngineApp(data: any) {
    const newEngineApp = {
      ...self.engineApp,
      ...data,
    }

    await mutateSelf(
      {
        ...self,
        engineApp: newEngineApp,
      },
      false,
    )

    void debouncedPutEngineApp(newEngineApp)
  }

  function getUserSchema(): DataSchema {
    return {
      type: 'object',
      properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        fields: engineApp?.userFieldsSchema,
      },
    }
  }

  async function changeWorkspace(
    newWorkspaceId: string | null,
    sufix?: string,
  ) {
    await apiClient.patch('/console-self', {
      defaultWorkspaceId: newWorkspaceId,
    })
    await mutateSelf()
    await router.push(`/w/${newWorkspaceId}${sufix ? `${sufix}` : ''}`)
  }

  async function checkWorkspaceAccess() {
    const isKnownWorkspace = workspaces?.map(({ id }) => id).includes(wsId)

    // if there are no workspaces or default workspace is not set then we redirect user to /initial-workspace page
    if (workspaces.length === 0 || !defaultWorkspaceId || !wsId) {
      await router.push('/initial-workspace', '/initial-workspace')
      return
    }

    // if user has access to current workspace then we don't need do anything
    if (isKnownWorkspace) {
      return
    }

    // if workspaceId is 0 then redirect to default workspace with the same path
    if (wsId === '0') {
      await router.replace(
        router.asPath.replace('/w/0', `/w/${defaultWorkspaceId}`),
      )

      return
    }

    // if user has default workspace but has no access to current workspace, and it is docs route then we redirect him to docs route with default workspace
    else if (
      defaultWorkspaceId &&
      defaultWorkspaceId !== wsId &&
      router.pathname === '/w/[workspaceId]/docs/[...docPath]'
    ) {
      await router.push({
        pathname: '/w/[workspaceId]/docs/[...docPath]',
        query: {
          workspaceId: defaultWorkspaceId,
          docPath: router.query.docPath,
        },
      })

      return
    }
  }

  useEffect(() => {
    if (selfLoading) return

    checkWorkspaceAccess()
      .catch(console.error)
      .finally(() => setChecked(true))
  }, [selfLoading])

  // remake token customer access token when workspace secret is changed
  useEffect(() => {
    if (!testCustomer?.id) return

    makeAccessToken(testCustomer?.internalId)
      .then(setTestCustomerAccessToken)
      .catch(console.error)
  }, [testCustomer?.id, workspace?.secret])

  const loading = selfLoading || !testCustomerClient

  const context = useDeepMemo<any, Partial<IWorkspaceContext>>(
    () => ({
      workspace,
      workspaces,

      patchWorkspace,
      changeWorkspace,
      archiveApp,
      loading,

      engineApp,
      patchEngineApp,
      engineAdminClient,
      engineAdminFetcher,
      engineAdminBinaryFetcher,

      testCustomerClient,
      testCustomerFetcher,
      testCustomerAccessToken,

      makeAccessToken,

      testCustomer,
      patchTestCustomer,

      getUserSchema,
    }),
    [
      workspace,
      workspaces,
      engineApp,
      engineAdminClient,
      testCustomerClient,
      testCustomerAccessToken,
      testCustomer,
    ],
  )

  if (!selfLoading && workspaces.length > 0 && !workspace) {
    return (
      <>
        <Flex
          flexDirection={'column'}
          alignItems={'start'}
          gap={3}
          width={'sm'}
          mx={'auto'}
          mt={44}
        >
          <Heading as={'h1'} fontWeight={'normal'} size={'2xl'} mt={0} mb={6}>
            Workspace with id {wsId} not found.
          </Heading>

          {workspaces.length && (
            <>
              <Text color='fg.muted' m={0}>
                You can switch to one of the available workspaces:
              </Text>

              <Flex width={'full'} gap='2'>
                <Combobox
                  minWidth={'fit-content'}
                  items={
                    workspaces
                      .map(convertWorkspaceToComboboxItem)
                      .filter(Boolean) as SelectItemType[]
                  }
                  variant={'outline'}
                  autoFocus={false}
                  value={selectedWsItem}
                  onValueChange={setSelectedWsItem}
                />
                <Button
                  variant={'solid'}
                  minWidth={'fit-content'}
                  onClick={() =>
                    void router.push(`/w/${selectedWsItem?.value}`)
                  }
                >
                  Go to workspace{' '}
                  <Icon>
                    <TbArrowRight />
                  </Icon>
                </Button>
              </Flex>
            </>
          )}

          {isAdmin && (
            <Flex flexDirection={'column'} alignItems={'start'} gap={3} mt={4}>
              <Text color='fg.muted' m={0}>
                Or impersonate workspace owner:
              </Text>
              <Button
                variant={'solid'}
                size={'sm'}
                colorPalette={'error'}
                onClick={() => impersonateWorkspaceOwner(wsId)}
              >
                Impersonate Owner
              </Button>
            </Flex>
          )}
        </Flex>
      </>
    )
  }

  if (loading || !checked) {
    return <EmptyPageLoader />
  }

  return (
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type '{ workspace: Workspace; workspaces: Workspac... Remove this comment to see the full error message
    <WorkspaceContext.Provider value={context}>
      {/* This client will be used for all the comboboxes in the UI by default */}
      <IntegrationAppClientProvider client={engineAdminClient}>
        {children}
      </IntegrationAppClientProvider>
    </WorkspaceContext.Provider>
  )
}

export function useWorkspace() {
  return useContext(WorkspaceContext)
}

function convertWorkspaceToComboboxItem(
  ws: Workspace | undefined,
): SelectItemType | undefined {
  if (!ws) return undefined
  return {
    label: ws?.name,
    value: ws?.id,
  }
}

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import * as Sentry from '@sentry/react'
import { AppProps } from 'next/app'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { HeadTitle } from 'components/AppLayout/HeadMetatags'
import { ErrorPopupProvider } from 'components/Error/ErrorPopup'
import AppErrorBoundary from 'components/ErrorBoundaries/AppErrorBoundary'
import { DisableReactErrorOverlay } from 'components/ErrorBoundaries/DisableReactErrorOverlay'
import { beforeSendSentryError } from 'components/ErrorBoundaries/sentryErrorHandler'
import { OnboardingProviderWrapper } from 'components/Onboarding/OnboardingProvider'
import { ENV_CONFIG } from 'config/env'
import { AuthProvider } from 'contexts/auth'
// eslint-disable-next-line import/order -- False positive error
import { TrackingContextProvider } from 'contexts/tracking-context'
import 'styles/globals.css'
import 'styles/tailwind.css'
import 'styles/graphql.css'
import '@integration-app/react/styles.css'

import { ImpersonationVisualNotification } from 'routes/components/Impersonation/VisualNotification'

if (ENV_CONFIG.ENABLE_TRACKING) {
  Sentry.init({
    dsn: 'https://281b07be93d245e99e11d6a90cbe59d1@o956397.ingest.sentry.io/5905751',
    beforeSend: beforeSendSentryError,
  })
}

export default function IntegrationConsoleApp({
  Component,
  pageProps,
}: AppProps) {
  return (
    <AppErrorBoundary>
      <ErrorPopupProvider>
        <TrackingContextProvider>
          <HelmetProvider>
            <HeadComponent />
            <DisableReactErrorOverlay />
            <AuthProvider>
              <OnboardingProviderWrapper>
                {ENV_CONFIG.ENABLE_TRACKING ? (
                  <GTMProvider state={{ id: 'GTM-TTWK8G4' }}>
                    <Component {...pageProps} />
                  </GTMProvider>
                ) : (
                  <Component {...pageProps} />
                )}
              </OnboardingProviderWrapper>
            </AuthProvider>
          </HelmetProvider>
        </TrackingContextProvider>
        <ImpersonationVisualNotification />
      </ErrorPopupProvider>
    </AppErrorBoundary>
  )
}

function HeadComponent() {
  return (
    <>
      <HeadTitle />
      <Helmet>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
        <meta name='zd-site-verification' content='vulro40cv5kehslnvriuwp' />
      </Helmet>
    </>
  )
}

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import { Truncate } from '../Truncate/index.mjs';
import { useDropdownItemAriaContext } from './item-aria-context.mjs';

function ItemTitle({ children }) {
  const styles = dropdownRecipe();
  const { titleId } = useDropdownItemAriaContext();
  return /* @__PURE__ */ jsx(
    Truncate,
    {
      className: styles["item-title"],
      id: titleId,
      "aria-label": typeof children === "string" ? children : void 0,
      placement: "top-start",
      offset: [0, 0],
      usePortal: true,
      children
    }
  );
}

export { ItemTitle };

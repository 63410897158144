"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { Switch } from '../../ui-kit/switch.mjs';

function Toggle(props) {
  return /* @__PURE__ */ jsx(Switch, { ...props, size: "md" });
}

export { Toggle };

import { UNIFIED_DATA_MODELS } from '@integration-app/sdk'
import { toHeaderCase } from 'js-convert-case'
import { useDebounceValue } from 'usehooks-ts'

import { SelectItemType, ComboboxProps, Combobox } from 'components/Select'

import { OptionItem } from './components'

const UDM_OPTIONS = Object.keys(UNIFIED_DATA_MODELS).map((udm) => ({
  value: udm,
  label: toHeaderCase(UNIFIED_DATA_MODELS[udm].pluralName),
}))

export function UdmComboboxSelect({
  value,
  onChange,
  name,
  ...props
}: Omit<ComboboxProps, 'items' | 'value' | 'onChange'> & {
  value?: string
  onChange: (value?: string) => void
  name?: string
}) {
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    150,
  )

  const filteredOptions = UDM_OPTIONS?.filter((o) => {
    return [o.label, o.value]
      .map((x) => x.toLowerCase())
      .some((x) => x.includes(debouncedSearchValue.toLowerCase()))
  })
  const selectedOption = UDM_OPTIONS?.find((o) => o.value === value)

  function handleChange(item: SelectItemType) {
    onChange(item?.value)
  }

  return (
    <Combobox
      items={filteredOptions}
      value={selectedOption}
      onValueChange={handleChange}
      placeholder={'Select an UDM'}
      onSearchInputChange={setDebouncedSearchValue}
      TriggerTextComponent={(props) => (
        <OptionItem asTrigger name={name} textWrap={'nowrap'} {...props} />
      )}
      ItemTextComponent={(props) => <OptionItem {...props} />}
      {...props}
    />
  )
}

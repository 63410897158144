"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { Text } from '../../ui-kit/styled/text.mjs';
import { Tooltip } from '../Tooltip/index.mjs';

function FieldDescription({ text }) {
  if (!text) return null;
  return /* @__PURE__ */ jsx(Tooltip, { tooltip: text, offset: [0, 4], placement: "bottom-start", children: /* @__PURE__ */ jsx(
    Text,
    {
      as: "span",
      display: "inline-block",
      px: "1",
      ml: "2",
      cursor: "help",
      bg: "neutral.2",
      rounded: "xs",
      color: "neutral.11",
      textAlign: "center",
      lineHeight: "1.2",
      minW: "5",
      _hover: {
        bg: "neutral.3"
      },
      children: "?"
    }
  ) });
}

export { FieldDescription };

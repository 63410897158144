/*
 * In this function we decide should we send an event to Sentry or not
 * Also we decide should we show a toast or not
 * */
import { AxiosError } from 'axios'

import { createErrorToast } from 'components/Error/createErrorToast'
import { ENV_CONFIG } from 'config/env'

import type { ErrorEvent, EventHint } from '@sentry/core'

export function beforeSendSentryError(event: ErrorEvent, hint: EventHint) {
  const originalException = hint.originalException as Error

  if (originalException?.name === 'AxiosError') {
    return handleAxiosErrors(event, originalException as AxiosError)
  }

  return event
}

function handleAxiosErrors(event: ErrorEvent, error: AxiosError) {
  if (error?.config?.baseURL === ENV_CONFIG.ENGINE_API_URI) {
    // HttpStatus.TOO_MANY_REQUESTS
    if (error?.response?.status === 429) {
      createErrorToast({
        ...error,
        message:
          (error.response?.data as Record<string, unknown>)?.message ||
          'Too many requests',
      })
      // We don't want to send this event to Sentry
      return null
    }
  }

  return event
}

import { PropsWithChildren, ReactNode } from 'react'
import { Portal } from '@ark-ui/react'
import { FloatingPortalBoundary } from '@integration-app/react'
import { TbChevronLeft, TbX } from 'react-icons/tb'
import { NavLink } from 'react-router-dom'

import { DebouncedInput, DebouncedInputProps } from 'components/DebouncedInput'
import { Menu } from 'components/Menu'
import { Tooltip } from 'components/Tooltip'
import { cx, sva, css } from 'styled-system/css'
import { HStack } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'
import { Icon } from 'ui-kit/icon'
import { IconButton } from 'ui-kit/icon-button'
import { Popup as UiKitPopup } from 'ui-kit/popup'

import {
  PopupWindowProps,
  PopupHeaderProps,
  PopupContentProps,
  PopupFooterProps,
  PopupTitleProps,
  TitleBackButtonProps,
  TitleBackButtonLinkProps,
} from './types'

const popupRecipe = sva({
  className: 'consolePopup',
  slots: ['header', 'headerInput', 'titleBackButton'],
  base: {
    header: {
      gridArea: 'header',
      display: 'flex',
      flexDirection: 'column',
      gap: '1',
      paddingInline: '5',
      paddingBlock: '3',
      borderBlockEndWidth: '1',
      borderBlockEndColor: 'border.subtle',
      '&:has(> .consolePopup__headerInput:first-child)': {
        paddingBlockStart: '0',
      },
      '&:has(> .consolePopup__headerInput:last-child)': {
        paddingBlockEnd: '0',
      },
    },
    headerInput: {
      zIndex: 'base',
      height: '12',
      width: 'auto',
      marginInline: '-5',
      paddingInlineStart: '5',
      paddingInlineEnd: '12',
      textStyle: 'lg',
      borderEndStartRadius: 'none',
      borderEndEndRadius: 'none',
      _hover: {
        backgroundColor: 'bg.subtle',
      },
      _focusWithin: {
        backgroundColor: 'bg.subtle',
      },
    },
    titleBackButton: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 'sm',
      paddingInlineStart: '1',
      paddingInlineEnd: '2',
      marginInlineStart: '-2',
      marginBlock: '-3',
      color: 'fg.muted',
      _hover: {
        color: 'fg.default',
      },
    },
  },
})

const popupClassnames = popupRecipe()

function Root({ children, ...props }: UiKitPopup.RootProps) {
  return (
    <UiKitPopup.Root lazyMount unmountOnExit {...props}>
      {children}
    </UiKitPopup.Root>
  )
}

function Window({
  children,
  usePortal,
  hideCloseButton,
  zIndex,
  ...props
}: PopupWindowProps) {
  return (
    <Portal disabled={!usePortal}>
      <UiKitPopup.Backdrop zIndex={zIndex} />
      <UiKitPopup.Positioner zIndex={zIndex}>
        <FloatingPortalBoundary>
          <Menu.PortalBoundary>
            <UiKitPopup.Window
              display='grid'
              gridTemplateAreas='"header" "content" "footer"'
              gridTemplateRows='min-content 1fr min-content'
              gap='0'
              {...props}
            >
              {children}
              {!hideCloseButton && (
                <UiKitPopup.CloseTrigger
                  asChild
                  position='absolute'
                  top='1.5'
                  right='1.5'
                  zIndex='docked'
                >
                  <IconButton
                    aria-label='Close Dialog'
                    variant='ghost'
                    size='xs'
                  >
                    <TbX />
                  </IconButton>
                </UiKitPopup.CloseTrigger>
              )}
            </UiKitPopup.Window>
          </Menu.PortalBoundary>
        </FloatingPortalBoundary>
      </UiKitPopup.Positioner>
    </Portal>
  )
}

function HeaderRoot({ children, className, ...props }: PopupHeaderProps) {
  return (
    <UiKitPopup.Header
      className={cx(popupClassnames.header, className)}
      {...props}
    >
      {children}
    </UiKitPopup.Header>
  )
}

function Content({ children, ...props }: PopupContentProps) {
  return (
    <UiKitPopup.Content
      gridArea='content'
      overflowY='auto'
      padding='4'
      {...props}
    >
      {children}
    </UiKitPopup.Content>
  )
}

function Footer({ children, ...props }: PopupFooterProps) {
  return (
    <UiKitPopup.Footer
      gridArea='footer'
      display='flex'
      justifyContent='flex-end'
      alignItems='center'
      gap='4'
      paddingInline='4'
      paddingBlockEnd='2'
      paddingBlockStart='2.5'
      backgroundColor='bg.canvas'
      borderBlockStartWidth='1'
      borderBlockStartColor='border.subtle'
      {...props}
    >
      {children}
    </UiKitPopup.Footer>
  )
}

function TitleRoot({
  children,
  leftSlot,
  rightSlot,
  ...props
}: PopupTitleProps & { leftSlot?: ReactNode; rightSlot?: ReactNode }) {
  if (!children && !leftSlot && !rightSlot) {
    return null
  }

  return (
    <HStack minHeight='4' paddingInlineEnd='8'>
      <SlotWrapper>{leftSlot}</SlotWrapper>
      {children && (
        <UiKitPopup.Title flexGrow='1' {...props}>
          {children}
        </UiKitPopup.Title>
      )}
      <SlotWrapper>{rightSlot}</SlotWrapper>
    </HStack>
  )
}

function SlotWrapper({ children }: PropsWithChildren) {
  if (!children) {
    return null
  }
  return children
}

function HeaderInput({ className, ...props }: DebouncedInputProps) {
  return (
    <DebouncedInput
      variant='ghost'
      size='lg'
      className={cx(popupClassnames.headerInput, className)}
      {...props}
    />
  )
}

function TitleBackButton({
  children,
  ...props
}: TitleBackButtonLinkProps | TitleBackButtonProps) {
  if (isTitleBackButtonLink(props)) {
    const { to, ...rest } = props
    return (
      <NavLink
        to={to}
        className={css({
          display: 'flex',
        })}
      >
        <TitleBackButton {...rest}>{children}</TitleBackButton>
      </NavLink>
    )
  }

  const { tooltip, ...rest } = props

  const button = (
    <Button
      variant='ghost'
      size='dense'
      className={popupClassnames.titleBackButton}
      {...rest}
    >
      <Icon>
        <TbChevronLeft />
      </Icon>
      {children}
    </Button>
  )

  if (tooltip) {
    return <Tooltip tooltip={tooltip}>{button}</Tooltip>
  }

  return button
}

function isTitleBackButtonLink(
  props: TitleBackButtonLinkProps | TitleBackButtonProps,
): props is TitleBackButtonLinkProps {
  return (props as TitleBackButtonLinkProps).to !== undefined
}

const Header = Object.assign(HeaderRoot, {
  Input: HeaderInput,
})

const Title = Object.assign(TitleRoot, {
  BackButton: TitleBackButton,
})

const Popup = {
  Root,
  Window,
  Header,
  Content,
  Footer,
  Title,
  Trigger: UiKitPopup.Trigger,
  CloseTrigger: UiKitPopup.CloseTrigger,
  Description: UiKitPopup.Description,
  Context: UiKitPopup.Context,
}
export { Popup }

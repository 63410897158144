import { v4 as uuid4 } from 'uuid'

import { showErrorInPopup } from 'components/Error/ErrorPopup'
import {
  hasErrorDocsLink,
  convertAnyErrorToErrorData,
  hasErrorAdditionalData,
} from 'components/Error/helpers'
import { toaster, createToast } from 'components/Toast'
import { ToastOptionsType } from 'components/Toast/components/SimpleToast'

export function createErrorToast(error: any) {
  const errorData = convertAnyErrorToErrorData(error)
  const toastId = uuid4()

  function handleActionClick() {
    toaster.pause(toastId)
    showErrorInPopup(errorData, {
      onOpenChange: ({ open }) => {
        if (open) {
          return
        }
        toaster.resume(toastId)
      },
    })
  }

  const toast: ToastOptionsType = {
    id: toastId,
    title: errorData.message,
    type: 'error',
  }

  if (hasErrorAdditionalData(errorData) || hasErrorDocsLink(errorData)) {
    toast.action = {
      label: 'Show details',
      onClick: handleActionClick,
    }
  }

  createToast(toast)
}

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { ComboBox } from '../../ComboBox/ComboBox.mjs';
import { getValueSpecFromSchema } from '../../ComboBox/value-spec.mjs';
import useDataBuilder from '../data-builder-context.mjs';
import { getFieldValueProps } from '../data-field.mjs';
import useDataBuilderField from '../field-context.mjs';
import { usIsFieldFrozen } from '../hooks.mjs';

function DefaultFieldValue({
  field,
  onChange,
  refsToIgnoreClick,
  topDropdownPanelComponent
}) {
  const { isEmpty, isRequired } = getFieldValueProps(field);
  const { isReadOnly } = useDataBuilder();
  const { autoOpen, setAutoOpen } = useDataBuilderField();
  const isFieldFrozen = usIsFieldFrozen();
  function handleDropdownClose() {
    setAutoOpen(false);
  }
  return /* @__PURE__ */ jsx(
    ComboBox,
    {
      value: isEmpty ? void 0 : field.value,
      schema: field.schema,
      onChange,
      valueSpec: getValueSpecFromSchema(field.schema),
      variablesSchema: field.variablesSchema,
      optionFactories: field.optionFactories,
      isRequired,
      disabled: isFieldFrozen || isReadOnly,
      autoOpen,
      onClose: handleDropdownClose,
      refsToIgnoreClick,
      topDropdownPanelComponent
    }
  );
}

export { DefaultFieldValue as default };

import { MouseEvent as ReactMouseEvent } from 'react'

import { useNavigateWithNativeBehavior } from 'hooks/useNavigateWithNativeBehavior'
import { routeTo } from 'routes/Workspaces/Workspace/routes-constants'

type RouteToFunctionProps<T extends keyof typeof routeTo> = Parameters<
  (typeof routeTo)[T]
>
type ReturnFunctionProps<T extends keyof typeof routeTo> =
  | RouteToFunctionProps<T>
  | [ReactMouseEvent, ...RouteToFunctionProps<T>]

export function useNavigateRouterTo<T extends keyof typeof routeTo>(
  routeKey: T,
): (...props: ReturnFunctionProps<T>) => void {
  const navigate = useNavigateWithNativeBehavior()

  return (...props: ReturnFunctionProps<T>) => {
    const route = routeTo[routeKey]

    if (
      props[0] instanceof Object &&
      'nativeEvent' in props[0] &&
      props[0]?.nativeEvent instanceof MouseEvent
    ) {
      const [event, ...parameters] = props as [
        ReactMouseEvent,
        ...RouteToFunctionProps<T>,
      ]
      // Apply the route function with the parameters tuple
      return navigate(
        // eslint-disable-next-line prefer-spread
        route.apply(null, parameters),
        event,
      )
    } else {
      return navigate(
        // eslint-disable-next-line prefer-spread
        route.apply(null, props),
      )
    }
  }
}

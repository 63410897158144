import { ReactNode } from 'react'
import { Options } from '@zag-js/toast'

import { cva } from 'styled-system/css'
import { Toast } from 'ui-kit/toast'

import { SimpleToast } from './components/SimpleToast'

type ToastType = 'primary' | 'success' | 'warning' | 'error'
type ToastOptionsType = Pick<
  Options<ReactNode>,
  'id' | 'title' | 'description' | 'action' | 'duration'
> & {
  type?: ToastType
}

const DEFAULT_TOAST_DURATION_MS = 1000 * 10 // 10 sec
const TOAST_DURATION: Record<ToastType, number> = {
  primary: 1000 * 30, // 30 sec
  success: 1000 * 10, // 10 sec
  warning: 1000 * 30, // 30 sec
  error: 1000 * 60, // 60 sec
}

const toaster = Toast.createToaster({
  id: 'toaster',
  placement: 'top-end',
  overlap: true,
  gap: 16,
})

const createToast = (args: ToastOptionsType) => {
  const duration = getToastDuration(args)
  return toaster.create({ ...args, duration })
}

function getToastDuration({ type, duration }: ToastOptionsType) {
  if (duration) {
    return duration
  }
  if (type) {
    return TOAST_DURATION?.[type]
  }
  return DEFAULT_TOAST_DURATION_MS
}

const toastContainerRecipe = cva({
  base: {},
  variants: {
    variant: {
      // When an error popup appears, we need to
      // hide a popover under the popup's overlay
      // and make it non-responsive to user interactions
      hasPopover: {
        zIndex: `{zIndex.overlay}!important`,
        '& > *': {
          pointerEvents: 'none!important',
          cursor: 'default',
        },
      },
    },
  },
})

function ToastContainer({ hasPopover }: { hasPopover?: boolean }) {
  const className = toastContainerRecipe({
    variant: hasPopover ? 'hasPopover' : undefined,
  })

  return (
    <Toast.Toaster toaster={toaster} className={className}>
      {(toast) => (
        <SimpleToast.Root
          key={toast.id}
          variant={(toast as ToastOptionsType).type}
        >
          {!!toast.title && (
            <SimpleToast.Title>{toast.title}</SimpleToast.Title>
          )}
          {!!toast.description && (
            <SimpleToast.Description>
              {toast.description}
            </SimpleToast.Description>
          )}
          <SimpleToast.ActionTrigger action={toast.action} />
        </SimpleToast.Root>
      )}
    </Toast.Toaster>
  )
}

export { toaster, createToast, ToastContainer }

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef, useId, useLayoutEffect } from 'react';
import { clsx } from 'clsx';
import { useIntersectionObserver } from 'usehooks-ts';
import { useForwardedRef } from '../../helpers/hooks/useForwardedRef.mjs';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import { DropdownItemAriaContextProvider } from './item-aria-context.mjs';
import { preventFocusShift } from './utils.mjs';

const Item = forwardRef(({ children, className, current = false, ...props }, ref) => {
  const titleId = useId();
  const styles = dropdownRecipe();
  return /* @__PURE__ */ jsx(DropdownItemAriaContextProvider, { value: { titleId }, children: /* @__PURE__ */ jsx(
    DropdownItemDiv,
    {
      ref,
      active: current,
      className: clsx(styles.item, className),
      "data-current": current || void 0,
      role: "listitem",
      "aria-labelledby": titleId,
      onMouseDown: preventFocusShift,
      ...props,
      children
    }
  ) });
});
const DropdownItemDiv = forwardRef(
  ({ children, active, ...props }, ref) => {
    if (active) {
      return /* @__PURE__ */ jsx(ScrollIntoViewDiv, { ...props, children });
    }
    return /* @__PURE__ */ jsx("div", { ref, ...props, children });
  }
);
const ScrollIntoViewDiv = forwardRef(({ children, ...props }, ref) => {
  const actualRef = useForwardedRef(ref);
  useScrollIntoViewOnce(actualRef);
  return /* @__PURE__ */ jsx("div", { ...props, ref: actualRef, children });
});
function useScrollIntoViewOnce(ref) {
  const threshold = 0.7;
  const freezeOnceVisible = true;
  const entry = useIntersectionObserver(ref, {
    threshold,
    freezeOnceVisible
  });
  useLayoutEffect(() => {
    if (!entry) {
      return;
    }
    if (
      // Hack for TS
      // IntersectionObserverEntry.isVisible is not in the type definition
      entry["isVisible"] || entry.isIntersecting && entry.intersectionRatio > 0.7
    ) {
      return;
    }
    entry.target.scrollIntoView({
      block: "nearest"
    });
  }, [entry]);
}

export { Item };

import { createContext, PropsWithChildren, useContext } from 'react'
import {
  useFlow,
  useFlowInstance,
  useExportFieldsAsCode,
} from '@integration-app/react'
import {
  Flow,
  FlowInstance,
  UpdateFlowInstanceRequest,
} from '@integration-app/sdk'

import { EmptyPageLoader } from 'components/Loader'

export function FlowInstanceContextProvider({
  id,
  children,
}: PropsWithChildren<{ id: string }>) {
  const {
    flowInstance,
    archive,
    openConfiguration,
    patch,
    setup,
    reset,
    refresh,
    put,
  } = useFlowInstance(id)
  const { flow } = useFlow(flowInstance?.flowId)

  const { code, onCodeChange, mutateCode } = useExportFieldsAsCode(
    'flow-instances',
    id,
    put,
  )

  const flowInstanceNodes = flowInstance?.nodes ?? {}

  async function onChange(data: any) {
    const update = {
      ...flowInstance,
      ...(data ?? {}),
      customized: {
        name: !flowInstance?.customized?.name ? !!data?.name : true,
        nodes: areNodesCustomized(data?.nodes),
      },
    }

    await put(update)

    await mutateCode()
    void refresh()
  }

  async function patchNode(key: string, data?: Record<string, any>) {
    return onChange({
      nodes: {
        ...flowInstanceNodes,
        [key]: {
          ...(flowInstanceNodes[key] ?? {}),
          ...(data ?? {}),
          isCustomized: true,
        },
      },
    })
  }

  async function putNode(key: string, data: Record<string, any>) {
    return onChange({
      nodes: {
        ...flowInstanceNodes,
        [key]: data,
      },
      customized: {
        ...(flowInstance?.customized ?? {}),
        nodes: true,
      },
    })
  }

  async function patchNodeConfig(key: string, data?: Record<string, any>) {
    const node = flowInstanceNodes[key]

    if (!node) {
      return
    }

    return patchNode(key, {
      config: {
        ...(node.config ?? {}),
        ...(data ?? {}),
      },
    })
  }

  async function patchNodeUi(key: string, data?: Record<string, any>) {
    const node = flowInstanceNodes[key]

    if (!node) {
      return
    }

    return patchNode(key, {
      ui: {
        ...(node.ui ?? {}),
        ...(data ?? {}),
      },
    })
  }

  function areNodesCustomized(newNodes: Record<string, any> = {}) {
    if (flowInstance?.customized?.nodes) {
      return true
    }
    if (!flow) {
      return true
    }
    const oldNodes = flow?.nodes ?? {}

    const oldKeys = Object.keys(oldNodes)
    const newKeys = Object.keys(newNodes)

    if (oldKeys.length !== newKeys.length) {
      return true
    }
    for (const key of oldKeys) {
      if (!newNodes[key]) {
        return true
      }
    }
    for (const key of newKeys) {
      if (!oldNodes[key]) {
        return true
      }
    }
    return false
  }

  if (!flowInstance || !id) {
    return <EmptyPageLoader />
  }

  return (
    <FlowInstanceContext.Provider
      value={{
        flowInstance,

        onChange,

        code,
        onCodeChange,

        patchNode,
        putNode,
        patchNodeConfig,
        patchNodeUi,

        patch,
        put,
        reset,
        refresh,
        setup,
        archive,
        openConfiguration: () => openConfiguration({}),
      }}
    >
      {children}
    </FlowInstanceContext.Provider>
  )
}

const FlowInstanceContext = createContext<{
  flowInstance: FlowInstance

  onChange(data: Partial<Flow>): Promise<any>

  code: any
  onCodeChange(newCode: any): Promise<void>

  patchNode(key: string, data: Record<string, any>): Promise<void>
  putNode(key: string, data: Record<string, any>): Promise<void>
  patchNodeConfig(key: string, data: Record<string, any>): Promise<void>
  patchNodeUi(key: string, data: Record<string, any>): Promise<void>

  setup(): Promise<void>
  reset(): Promise<void>
  refresh(): Promise<FlowInstance>
  patch(data: Partial<UpdateFlowInstanceRequest>): Promise<void>
  put(data: FlowInstance): Promise<void>
  archive(): Promise<void>

  openConfiguration(): Promise<void>
}>({} as any)

export function useFlowInstanceContext() {
  return useContext(FlowInstanceContext)
}

import { ReactNode, useState } from 'react'
import { TbWindowMaximize, TbWindowMinimize } from 'react-icons/tb'

import { IconButton } from 'components/Button/IconButton'
import { Heading } from 'ui-kit/heading'
import clsx from 'utils/clsx'

import classes from './ErrorMessage.module.css'

function ErrorMessageRoot({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return <div className={clsx(classes.wrapper, className)}>{children}</div>
}

function ErrorMessageHeader({ children }) {
  const [minimized, setMinimized] = useState(false)
  function toggleMinimized() {
    setMinimized((m) => !m)
  }

  return (
    <div
      className={clsx(classes.header, minimized && classes.header__minimized)}
    >
      <Heading as='h3'>{children}</Heading>
      <IconButton
        size='sm'
        tooltip={minimized ? 'Maximize' : 'Minimize'}
        onClick={toggleMinimized}
      >
        {minimized ? <TbWindowMaximize /> : <TbWindowMinimize />}
      </IconButton>
    </div>
  )
}
function ErrorMessageContent({ children }) {
  return <div className={classes.content}>{children}</div>
}

const ErrorMessage = Object.assign(ErrorMessageRoot, {
  Header: ErrorMessageHeader,
  Content: ErrorMessageContent,
})
export default ErrorMessage

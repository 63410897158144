"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { DataBuilderForm } from './Form.mjs';
import { makeDataField } from './index.mjs';

function FieldList({
  schema,
  value,
  variablesSchema,
  isAdminMode,
  frozenFieldsLocators = [],
  onChange,
  collapsed,
  hideReadOnlyFields
}) {
  if (schema?.type !== "object") {
    return /* @__PURE__ */ jsx("span", { children: "Something went wrong. Trying to display a field list for schema that is not an object." });
  }
  const field = makeDataField({
    schema,
    value,
    variablesSchema
  });
  return /* @__PURE__ */ jsx(
    DataBuilderForm,
    {
      field,
      onChange,
      isAdminMode,
      frozenFieldsLocators,
      collapsed,
      hideReadOnlyFields
    }
  );
}

export { FieldList };

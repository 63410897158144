"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { useRef } from 'react';
import { isFormula, getFormula } from '@integration-app/sdk';
import { useComboBox } from '../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { useComboBoxDropdownSearchContext } from '../context/combobox-dropdown-search.mjs';
import { Dropdown } from '../Dropdown/Dropdown.mjs';
import { DropdownCategoryType } from '../types.mjs';
import { LexicalEditor } from '../../ComboBoxElements/Editor/editor.mjs';
import { isClickOnEditorCustomTagComponent } from '../../ComboBoxElements/Editor/helpers/clicks.mjs';
import { transformEditorSchemaToValue } from '../../ComboBoxElements/Editor/helpers/transformEditorSchemaToValue.mjs';
import { useEditor } from '../../ComboBoxElements/Editor/useEditor.mjs';
import { FieldWrapper } from '../../ComboBoxElements/FieldWrapper.mjs';
import { getFormulas } from '../../DataBuilder/Formula/index.mjs';
import { getRefElement } from '../../../helpers/getRefElement.mjs';
import { isDefinedValue } from '../Value/isDefinedValue.mjs';

function TextInput({
  placeholder,
  className,
  onChange,
  disabled,
  required
}) {
  const textInputRef = useRef(null);
  const fieldValueRef = useRef(null);
  const {
    value,
    refsToIgnoreClick,
    schema,
    // Dropdown related
    isDropdownOpen,
    openDropdown,
    closeDropdown,
    topDropdownPanelComponent
  } = useComboBox();
  const { setSearchSource, searchSource } = useComboBoxDropdownSearchContext();
  const { pasteTag } = useEditor();
  const { activeCategory } = useComboBoxDropdownContext();
  function handleOpenDropdown() {
    if (disabled) return;
    openDropdown();
  }
  const refs = [textInputRef, fieldValueRef, ...refsToIgnoreClick ?? []];
  const handleClick = function(e) {
    const isClickInsideAnyOfElements = refs.map(getRefElement).some((el) => el.contains(e?.target));
    if (isClickInsideAnyOfElements && !isClickOnEditorCustomTagComponent(
      e?.target,
      fieldValueRef?.current
    )) {
      handleOpenDropdown();
      e?.stopPropagation();
    }
  };
  const handleOptionSelect = (value2) => {
    if (shouldRewriteValue(activeCategory)) {
      onChange(value2);
      if (shouldCloseDropdown(activeCategory)) {
        closeDropdown();
      }
    } else {
      const openTagCombobox = valueHasDropdownWithFormula(value2);
      if (openTagCombobox) {
        closeDropdown();
      }
      pasteTag({
        value: value2,
        autoOpen: openTagCombobox,
        allowEditorSearchClear: searchSource === "editor"
      });
    }
  };
  const requiredAndEmptyField = required && isDefinedValue(value);
  return /* @__PURE__ */ jsx(
    FieldWrapper,
    {
      role: "combobox",
      ref: fieldValueRef,
      "aria-expanded": isDropdownOpen,
      onClick: handleClick,
      disabled,
      requiredAndEmptyField,
      children: /* @__PURE__ */ jsx(
        Dropdown,
        {
          topDropdownPanelComponent,
          onOptionSelect: handleOptionSelect,
          autoFocusOnSearch: false,
          editorRef: textInputRef,
          children: /* @__PURE__ */ jsx(
            LexicalEditor,
            {
              ref: textInputRef,
              className,
              onFocus: () => {
                setSearchSource("editor");
              },
              disabled,
              value,
              schema,
              placeholder,
              onChange: (state) => {
                onChange(transformEditorSchemaToValue(state));
              }
            }
          )
        }
      )
    }
  );
}
function shouldRewriteValue(category) {
  return category && [
    DropdownCategoryType.OPTIONS,
    DropdownCategoryType.CUSTOM,
    DropdownCategoryType.LOOKUP_OPTIONS,
    DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY
  ].includes(category);
}
function shouldCloseDropdown(category) {
  return category && [
    DropdownCategoryType.OPTIONS,
    DropdownCategoryType.CUSTOM,
    DropdownCategoryType.LOOKUP_OPTIONS
  ].includes(category);
}
function valueHasDropdownWithFormula(value) {
  if (!isFormula(value)) return false;
  return !!getFormulas()[getFormula(value)?.type]?.DropdownComponent;
}

export { TextInput };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import classes from './styles.module.css.mjs';
import { SvgIcon } from '../SvgIcon/index.mjs';

const Formula = forwardRef(
  ({ children, onClick }, ref) => {
    function handleClick() {
      onClick?.();
    }
    return /* @__PURE__ */ jsx("div", { className: classes.formula, onClick: handleClick, ref, children: /* @__PURE__ */ jsx("div", { className: classes.variables, children }) });
  }
);
Formula.displayName = "Formula";
function Variable({ children }) {
  return /* @__PURE__ */ jsx("div", { className: classes.variable, children });
}
function VariableSvgIcon({ type }) {
  return /* @__PURE__ */ jsx(SvgIcon, { type, className: classes.variableSvgIcon });
}

export { Formula, Variable, VariableSvgIcon };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { SvgIconType } from '../SvgIcon/svg-icon-type.mjs';
import { dropdownRecipe } from '../../recipes/dropdown.mjs';
import { ItemIcon } from './ItemIcon.mjs';

const InfoHeading = forwardRef(({ className, text, ...props }, ref) => {
  const styles = dropdownRecipe();
  return /* @__PURE__ */ jsxs(
    "span",
    {
      role: "heading",
      "aria-level": 2,
      className: clsx(styles["info-heading"], className),
      ...props,
      ref,
      children: [
        /* @__PURE__ */ jsx(
          ItemIcon,
          {
            type: SvgIconType.InfoCircle,
            className: styles["info-heading-icon"]
          }
        ),
        text
      ]
    }
  );
});

export { InfoHeading };

import React, {
  ComponentProps,
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useId,
  useRef,
} from 'react'
import { Portal, Menu as MenuArkKit } from '@ark-ui/react'
import { TbChevronRight } from 'react-icons/tb'

import { sva } from 'styled-system/css'
import { Box } from 'styled-system/jsx'
import { Icon } from 'ui-kit/icon'
import { Menu as MenuUiKit } from 'ui-kit/menu'
import { createSlotRecipeContext } from 'ui-kit/utils/create-slot-recipe-context'

type MenuItem<T = string> = {
  value: T
  label: string
  icon?: ReactNode
  onClick?: MenuArkKit.ItemProps['onClick']
}
type MenuSection = {
  label: string
  items: MenuItem[]
  icon?: ReactNode
}

export type MenuItemType<T = string> = MenuItem<T> | MenuSection

const menuRecipe = sva({
  className: 'menuRecipe',
  slots: ['root', 'item', 'iconSlot', 'chevronSlot'],
  base: {
    root: {},
    item: {
      alignItems: 'center',
      justifyContent: 'start',
      gap: 2,
    },
    iconSlot: {
      flexShrink: 0,
      flexGrow: 0,
      '& > svg': {
        display: 'block',
      },
      '& > img': {
        display: 'block',
      },
    },
    chevronSlot: {
      marginInlineStart: 'auto',
    },
  },
})

const context = createSlotRecipeContext(menuRecipe)

const BOUNDARY_DATA_KEY = 'data-menu-portal-boundary'
const PortalBoundaryContext = createContext<string | null>(null)

// FIXME: createSlotRecipeContext.withProvider doesn't support JSX.Element
// @ts-expect-error TS2559: Type MenuRootProps has no properties in common with type { className?: string | undefined; }
const Root = context.withProvider<MenuArkKit.RootProps>(
  MenuUiKit.Root,
  'root',
  {
    forwardVariantsToSlots: ['onSelect', 'highlightedValue'],
    props: {
      lazyMount: true,
      unmountOnExit: true,
    },
  },
)

function MenuPortalBoundary({
  children,
  id,
  ...props
}: {
  children: React.ReactElement
  id?: string
} & React.HTMLAttributes<HTMLElement>) {
  const generatedId = useId()

  if (!React.isValidElement(children)) {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        'Error: MenuPortalBoundary must have exactly one child element',
      )
    }

    return (
      <PortalBoundaryContext.Provider value={id || generatedId}>
        {children}
      </PortalBoundaryContext.Provider>
    )
  }

  return (
    <PortalBoundaryContext.Provider value={id || generatedId}>
      {React.cloneElement(children, {
        [BOUNDARY_DATA_KEY]: id || generatedId,
        ...props,
        ...children.props,
      })}
    </PortalBoundaryContext.Provider>
  )
}

function MenuPortal({
  children,
  disabled,
}: {
  children?: React.ReactNode
  disabled?: boolean
}) {
  const boundaryId = useContext(PortalBoundaryContext)
  const containerRef = useRef<HTMLElement | null>(null)

  const container = boundaryId
    ? document.querySelector<HTMLElement>(
        `[${BOUNDARY_DATA_KEY}="${boundaryId}"]`,
      )
    : null

  containerRef.current = container

  return (
    <Portal container={containerRef} disabled={disabled}>
      {children}
    </Portal>
  )
}

function Content({
  children,
  usePortal,
  onClick,
  ...props
}: PropsWithChildren<
  MenuArkKit.PositionerBaseProps &
    Pick<ComponentProps<typeof MenuUiKit.Content>, 'onClick'> & {
      usePortal?: boolean
    }
>) {
  return (
    <MenuPortal disabled={!usePortal}>
      <MenuUiKit.Positioner {...props}>
        <MenuUiKit.Content onClick={onClick}>
          <MenuUiKit.ItemGroup>{children}</MenuUiKit.ItemGroup>
        </MenuUiKit.Content>
      </MenuUiKit.Positioner>
    </MenuPortal>
  )
}

function Items({
  items,
  ...props
}: MenuArkKit.PositionerBaseProps & {
  items: MenuItemType[]
  usePortal?: boolean
}) {
  const { forwardedProps } = context.use()
  const itemsWithKeys: Array<MenuItemType & { key: number }> = items.map(
    (item, index) => ({
      ...item,
      key: index,
    }),
  )
  return (
    <Content {...props}>
      {itemsWithKeys.map((item) => {
        if (isMenuItemSection(item)) {
          const { label, items, key, ...props } = item
          return (
            <Root
              {...forwardedProps}
              positioning={{ placement: 'right-start', gutter: -2 }}
              key={key}
            >
              <TriggerItem {...props}>{label}</TriggerItem>
              <Items items={items} />
            </Root>
          )
        }
        const { label, key, ...props } = item
        return (
          <Item key={key} {...props}>
            {label}
          </Item>
        )
      })}
    </Content>
  )
}

function isMenuItemSection(item: MenuItemType): item is MenuSection {
  return (item as MenuSection).items !== undefined
}

function Item({
  children,
  icon,
  ...props
}: PropsWithChildren<
  Omit<MenuArkKit.ItemProps, 'asChild'> & {
    icon?: ReactNode
  }
>) {
  const { slotStyles } = context.use()
  return (
    <MenuUiKit.Item className={slotStyles.item} {...props}>
      {icon && (
        <Box className={slotStyles.iconSlot}>
          <Icon>{icon}</Icon>
        </Box>
      )}
      {children}
    </MenuUiKit.Item>
  )
}

function TriggerItem({
  children,
  icon,
  ...props
}: PropsWithChildren<
  Omit<MenuArkKit.TriggerItemBaseProps, 'asChild'> & {
    icon?: ReactNode
  }
>) {
  const { slotStyles } = context.use()
  return (
    <MenuUiKit.TriggerItem className={slotStyles.item} {...props}>
      {icon && (
        <Box className={slotStyles.iconSlot}>
          <Icon>{icon}</Icon>
        </Box>
      )}
      {children}
      <Box className={slotStyles.chevronSlot}>
        <Icon>
          <TbChevronRight />
        </Icon>
      </Box>
    </MenuUiKit.TriggerItem>
  )
}

const Menu = {
  Root,
  PortalBoundary: MenuPortalBoundary,
  Trigger: MenuUiKit.Trigger,
  Content,
  Items,
  Item,
  TriggerItem,
}

export { Menu }

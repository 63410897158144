import dynamic from 'next/dynamic'

import { cx } from 'styled-system/css'
import { Box, BoxProps } from 'styled-system/jsx'

import classes from './JsonViewer.module.css'

// disable ssr
const DynamicReactJsonView = dynamic(
  () => import('@microlink/react-json-view'),
  {
    ssr: false,
  },
)

export function JsonViewer({
  json,
  collapsed = false,
  className,
  ...props
}: {
  json?: any
  collapsed?: boolean | number
} & BoxProps) {
  if (typeof window === 'undefined') return null

  if (json === null || json === undefined) {
    return '<empty data>'
  }

  if (typeof json !== 'object') {
    return json.toString()
  }

  return (
    <Box {...props} className={cx(classes.json_viewer, className)}>
      <DynamicReactJsonView
        name={null} // disable root name
        iconStyle='square'
        displayDataTypes={false}
        displayObjectSize={false}
        quotesOnKeys={false}
        theme='rjv-default'
        src={json ?? {}}
        collapsed={collapsed}
        shouldCollapse={({ namespace, src, type }) => {
          const tooDeep = namespace.length > 2 // collapse at depth 3
          const tooManyItems = type === 'array' && (src as any[]).length > 20 // collapse arrays with more than 20 items

          return tooDeep || tooManyItems
        }}
      />
    </Box>
  )
}

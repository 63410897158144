"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { getFormula, DataBuilderFormulaType, Formula } from '@integration-app/sdk';
import useEventStopPropagation from '../../../helpers/hooks/useEventStopPropagation.mjs';
import { ComboBox } from '../../ComboBox/ComboBox.mjs';
import { NestedLevel, Row, Cell, ActionBar } from '../../DataBuilderUI/index.mjs';
import { makeSubField } from '../data-field.mjs';
import { DataBuilderFieldProvider } from '../field-context.mjs';
import DataBuilderFormFieldValue from '../FieldValue/index.mjs';
import { DataBuilderFieldControlPanel } from '../Nested/FieldControlPanel/index.mjs';

function DataBuilderCondition({
  field,
  onChange,
  onDelete,
  isNested
}) {
  const formula = getFormula(field.value);
  switch (formula?.type) {
    case DataBuilderFormulaType.AND:
      return /* @__PURE__ */ jsx(And, { field, onChange });
    case DataBuilderFormulaType.EVAL:
    default:
      return /* @__PURE__ */ jsx(
        EvalCondition,
        {
          field,
          onChange,
          onDelete,
          isNested
        }
      );
  }
}
function And({
  field,
  onChange
}) {
  const formula = getFormula(field.value);
  let conditions = formula?.args;
  if (!conditions || !Array.isArray(conditions) || conditions.length === 0) {
    conditions = [null];
  }
  function getConditionField(idx) {
    const value = conditions[idx];
    return makeSubField(field, {
      value,
      valueLocator: `${field.valueLocator}.$and[${idx}]`,
      // Temporary: remove this when we implement the new conditions UI
      level: field.level
    });
  }
  function handleConditionChange(idx, value) {
    const newConditions = [...conditions];
    newConditions[idx] = value;
    onChange({
      $and: newConditions
    });
  }
  function handleAddCondition() {
    onChange({
      $and: [...conditions, null]
    });
  }
  function handleDeleteCondition(idx) {
    const newConditions = [...conditions];
    newConditions.splice(idx, 1);
    onChange({
      $and: newConditions
    });
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    conditions.map((_condition, idx) => /* @__PURE__ */ jsx(
      DataBuilderCondition,
      {
        field: getConditionField(idx),
        onChange: (value) => handleConditionChange(idx, value),
        onDelete: () => handleDeleteCondition(idx),
        isNested: true
      },
      idx
    )),
    /* @__PURE__ */ jsx(DataBuilderFieldControlPanel, { field, onAdd: handleAddCondition })
  ] });
}
function EvalCondition({
  field,
  onChange,
  onDelete,
  isNested
}) {
  const formula = getFormula(field.value);
  let value;
  let operator;
  let operatorOptions = [];
  let operand;
  if (formula instanceof Formula.Eval) {
    value = formula.value;
    operator = formula.getOperator(field.variablesSchema);
    operand = formula.operand;
    operatorOptions = formula.getAvailableOperators(field.variablesSchema).map((op) => ({
      label: op.name,
      value: op.key
    }));
  }
  const valueField = makeSubField(field, {
    value,
    valueLocator: `${field.valueLocator}.$eval`
  });
  const operandField = makeSubField(field, {
    value: operand,
    /* FIXME: strictNullCheck temporary fix */
    // @ts-expect-error TS(2454): Variable 'operator' is used before being assigned.
    valueLocator: `${field.valueLocator}.${operator?.key}`,
    /* FIXME: strictNullCheck temporary fix */
    // @ts-expect-error TS(2454): Variable 'operator' is used before being assigned.
    schema: operator?.operandSchema
  });
  function handleValueChange(value2) {
    onChange({
      ...formula?.toObject() ?? {},
      $eval: value2
    });
  }
  function handleOperatorChange(operatorKey) {
    const newValue = {
      $eval: value
    };
    if (operatorKey) {
      newValue[operatorKey] = operand ?? null;
    }
    onChange(newValue);
  }
  function handleOperandChange(operand2) {
    onChange({
      $eval: value,
      [operator.key]: operand2
    });
  }
  function handleTransformToAnd() {
    onChange({
      $and: [field.value, null]
    });
  }
  const hasOperand = operator && operator.hasOperand;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(NestedLevel, { level: field.level, children: /* @__PURE__ */ jsxs(Row, { children: [
      /* @__PURE__ */ jsx(Cell.Combobox, { children: /* @__PURE__ */ jsx(DataBuilderFieldProvider, { field: valueField, children: /* @__PURE__ */ jsx(
        ComboBox,
        {
          placeholder: "Value",
          value: valueField.value,
          onChange: handleValueChange,
          variablesSchema: valueField.variablesSchema,
          optionFactories: valueField.optionFactories,
          schema: void 0
        }
      ) }) }),
      /* @__PURE__ */ jsx(Cell.Combobox, { disabled: !value, children: value && /* @__PURE__ */ jsx(
        ComboBox,
        {
          value: operator?.key,
          options: operatorOptions,
          onChange: handleOperatorChange
        }
      ) }),
      /* @__PURE__ */ jsx(Cell.Combobox, { grow: true, disabled: !hasOperand, children: hasOperand && /* @__PURE__ */ jsx(DataBuilderFieldProvider, { field: operandField, children: /* @__PURE__ */ jsx(
        DataBuilderFormFieldValue,
        {
          field: operandField,
          onChange: handleOperandChange,
          allowScalarArray: true
        }
      ) }) }),
      onDelete && /* @__PURE__ */ jsx(ActionBar, { children: /* @__PURE__ */ jsx(
        ActionBar.DeleteButton,
        {
          onClick: useEventStopPropagation(onDelete)
        }
      ) })
    ] }) }),
    !isNested && /* @__PURE__ */ jsx(
      DataBuilderFieldControlPanel,
      {
        field,
        onAdd: handleTransformToAnd
      }
    )
  ] });
}

export { DataBuilderCondition as default };

import { ComponentProps, ReactNode } from 'react'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { Options } from '@zag-js/toast'
import {
  TbAlertCircle,
  TbAlertTriangle,
  TbCircleCheck,
  TbInfoCircle,
  TbX,
} from 'react-icons/tb'

import { css, cx, RecipeVariantProps, sva } from 'styled-system/css'
import { Flex } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'
import { Icon, IconProps } from 'ui-kit/icon'
import { IconButton } from 'ui-kit/icon-button'
import { Toast } from 'ui-kit/toast'

const toastRecipe = sva({
  className: 'consoleToast',
  slots: ['root', 'icon'],
  base: {
    root: {
      position: 'relative',
      px: 4,
      pt: 2.5,
      pb: 3,
      _after: {
        pointerEvents: 'none',
        position: 'absolute',
        display: 'block',
        top: 0,
        bottom: 0,
        left: 0,
        width: 8,
        borderTopLeftRadius: 'l3',
        borderBottomLeftRadius: 'l3',
        boxShadow: '-3px 0 0 0px var(--shadow-color)',
        // Fix unwanted shadow effect
        transform: 'rotateY(0.01deg)',
      },
      '&:has(.consoleToast__icon)': {
        paddingInlineStart: 8,
      },
    },
    icon: {
      position: 'absolute',
      top: 3,
      left: 2,
      width: 4,
      height: 4,
    },
  },
  variants: {
    variant: {
      primary: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'primary.emphasized',
          },
        },
        icon: {
          color: 'primary.text',
        },
      },
      success: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'success.emphasized',
          },
        },
        icon: {
          color: 'success.text',
        },
      },
      warning: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'warning.emphasized',
          },
        },
        icon: {
          color: 'warning.text',
        },
      },
      error: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'error.emphasized',
          },
        },
        icon: {
          color: 'error.text',
        },
      },
    },
  },
})

export type ToastVariants = RecipeVariantProps<typeof toastRecipe>

export type ToastOptionsType = Omit<
  Options<ReactNode>,
  'type' | 'placement'
> & {
  type?: 'primary' | 'success' | 'warning' | 'error'
}

function Root({
  children,
  className,
  ...props
}: ComponentProps<typeof Toast.Root> & ToastVariants) {
  const [variantProps, restProps] = toastRecipe.splitVariantProps(props)
  const slotStyles = toastRecipe(variantProps)

  return (
    <Toast.Root className={cx(slotStyles.root, className)} {...restProps}>
      <Toast.CloseTrigger top={1} right={1} asChild>
        <IconButton size='xs' variant='ghost' aria-label='Close'>
          <TbX />
        </IconButton>
      </Toast.CloseTrigger>
      <ToastIcon
        className={slotStyles.icon}
        size={'sm'}
        variant={variantProps.variant}
      />
      <Flex flexDirection={'column'} gap={1}>
        {children}
      </Flex>
    </Toast.Root>
  )
}

const toastActionRecipeClassname = css({
  color: 'fg.muted',
  fontSize: 'sm',
  justifyContent: 'start',
  _hover: {
    color: 'primary.text',
    colorPalette: 'primary',
  },
})
function ActionTrigger({ action }: { action?: ToastOptionsType['action'] }) {
  if (!action) return null

  const handleActionClick = useEventStopPropagation(action.onClick)

  return (
    <Toast.ActionTrigger asChild>
      <Button
        variant='link'
        colorPalette='primary'
        onClick={handleActionClick}
        className={toastActionRecipeClassname}
      >
        {action.label}
      </Button>
    </Toast.ActionTrigger>
  )
}

function ToastIcon({
  variant,
  ...props
}: IconProps & { variant?: ToastOptionsType['type'] }) {
  const icon = getToastIcon(variant)

  if (!icon) {
    return null
  }

  return <Icon {...props}>{icon}</Icon>
}

function getToastIcon(type?: ToastOptionsType['type']) {
  switch (type) {
    case 'primary':
      return <TbInfoCircle />
    case 'success':
      return <TbCircleCheck />
    case 'warning':
      return <TbAlertTriangle />
    case 'error':
      return <TbAlertCircle />
    default:
      return null
  }
}

const SimpleToast = {
  Root,
  Title: Toast.Title,
  Description: Toast.Description,
  ActionTrigger,
}
export { SimpleToast }

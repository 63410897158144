// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '2b29645662244af8bedf522d656afaa3';
const css =`._fieldWrapper_ifze0_1{align-items:baseline;display:flex;flex-direction:row}._fieldKey__static_ifze0_1{cursor:default}._fieldValue__invalid_ifze0_1,._notification_ifze0_1{--tw-text-opacity:1;color:rgb(168 54 5/var(--tw-text-opacity,1))}._notification_ifze0_1{margin-left:-.5rem;width:.5rem}`;
const styles = {
    get ['fieldWrapper']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldWrapper_ifze0_1'; },
    get ['fieldKey__static']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldKey__static_ifze0_1'; },
    get ['fieldKeyStatic']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldKey__static_ifze0_1'; },
    get ['fieldValue__invalid']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldValue__invalid_ifze0_1'; },
    get ['fieldValueInvalid']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldValue__invalid_ifze0_1'; },
    get ['notification']() { injectStyles(key, css, options, undefined, undefined);  return '_notification_ifze0_1'; },
    inject(shadowRoot) { injectStyles(key, css, options, undefined, shadowRoot); }
};
export { styles, css, key };
export default styles;

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { IconPlus, IconAlertCircle, IconAlertOctagon, IconAlignLeft, IconArchive, IconArrowRight, IconArrowBack, IconBraces, IconX, IconCheck, IconChecks, IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp, IconCode, IconCopy, IconTrash, IconPencil, IconExternalLink, IconEye, IconEyeOff, IconMathFunction, IconMenu2, IconInfoCircle, IconLink, IconPlaystationCircle, IconFilter, IconPlayerPlay, IconQuestionMark, IconRefresh, IconToggleLeft, IconBox, IconSignature, IconSearch, IconSettings, IconArrowLeftCircle, IconArrowRightCircle, IconDots, IconUnlink, IconVariable, IconArrowDownLeft, IconArrowUpRight, IconZoomCancel, IconCircleArrowUpRight, IconDatabase, IconLine, IconUsers, IconUser, IconBolt, IconPlaylistAdd, IconChartHistogram, IconPlugConnected, IconHistory, IconActivity, IconSocial, IconBuildingSkyscraper, IconNotebook, IconCoin, IconShoppingCart, IconCalendarEvent, IconChecklist, IconNotes, IconMail, IconStack2, IconRotate } from '@tabler/icons-react';
import { SvgIconUnifiedInterfaces, SvgIconUnifiedInterface, SvgIconApp, SvgIconFieldMappings, SvgIconFieldMapping, SvgIconFlows, SvgIconFlow, SvgIconDataSources, SvgIconObjectLinks, SvgIconObjectLink, SvgIconAppEventSubscriptions, SvgIconDataSchemas, SvgIconDataSchema } from './BasicEntities.mjs';
import { SvgIconType } from './svg-icon-type.mjs';

function getIconProps(type) {
  switch (type) {
    // In case of using icons from different packs
    // we could use different settings
    case SvgIconType.DotsVertical:
    case SvgIconType.SchemaType_number:
    case SvgIconType.SchemaType_request:
      return {};
    case SvgIconType.PlayerPlayFilled:
      return {
        strokeWidth: 1.2,
        fill: "currentColor"
      };
    default:
      return {
        strokeWidth: 1.2
      };
  }
}
const icons = /* @__PURE__ */ new Map();
icons.set(SvgIconType.Add, IconPlus);
icons.set(SvgIconType.AlertCircle, IconAlertCircle);
icons.set(SvgIconType.AlertOctagon, IconAlertOctagon);
icons.set(SvgIconType.AlertTriangle, SvgIconAlertTriangle);
icons.set(SvgIconType.AlignLeft, IconAlignLeft);
icons.set(SvgIconType.AppIcon, IntegrationAppIcon);
icons.set(SvgIconType.Archive, IconArchive);
icons.set(SvgIconType.Array, SvgIconArray);
icons.set(SvgIconType.ArrowRight, IconArrowRight);
icons.set(SvgIconType.Back, IconArrowBack);
icons.set(SvgIconType.Braces, IconBraces);
icons.set(SvgIconType.Cancel, IconX);
icons.set(SvgIconType.Check, IconCheck);
icons.set(SvgIconType.Checks, IconChecks);
icons.set(SvgIconType.ChevronDown, IconChevronDown);
icons.set(SvgIconType.ChevronLeft, IconChevronLeft);
icons.set(SvgIconType.ChevronRight, IconChevronRight);
icons.set(SvgIconType.ChevronUp, IconChevronUp);
icons.set(SvgIconType.Close, IconX);
icons.set(SvgIconType.Code, IconCode);
icons.set(SvgIconType.Copy, IconCopy);
icons.set(SvgIconType.DefaultWorkspaceIcon, DefaultWorkspaceLogo);
icons.set(SvgIconType.Delete, IconTrash);
icons.set(SvgIconType.DotsVertical, SvgIconThreeDotsVertical);
icons.set(SvgIconType.Edit, IconPencil);
icons.set(SvgIconType.ExternalLink, IconExternalLink);
icons.set(SvgIconType.Eye, IconEye);
icons.set(SvgIconType.EyeOff, IconEyeOff);
icons.set(SvgIconType.Function, IconMathFunction);
icons.set(SvgIconType.HamburgerMenu, IconMenu2);
icons.set(SvgIconType.InfoCircle, IconInfoCircle);
icons.set(SvgIconType.Link, IconLink);
icons.set(SvgIconType.ListOption, IconPlaystationCircle);
icons.set(SvgIconType.Loader, SvgIconLoader);
icons.set(SvgIconType.Lookup, IconFilter);
icons.set(SvgIconType.PlayerPlay, IconPlayerPlay);
icons.set(SvgIconType.PlayerPlayFilled, IconPlayerPlay);
icons.set(SvgIconType.QuestionMark, IconQuestionMark);
icons.set(SvgIconType.Refresh, IconRefresh);
icons.set(SvgIconType.Reset, SvgIconReset);
icons.set(SvgIconType.SchemaType_array, IconMenu2);
icons.set(SvgIconType.SchemaType_boolean, IconToggleLeft);
icons.set(SvgIconType.SchemaType_connection, IconLink);
icons.set(SvgIconType.SchemaType_integer, SvgIconSchemaTypeInteger);
icons.set(SvgIconType.SchemaType_number, SvgIconSchemaTypeNumber);
icons.set(SvgIconType.SchemaType_object, IconBox);
icons.set(SvgIconType.SchemaType_request, SvgIconSchemaTypeRequest);
icons.set(SvgIconType.SchemaType_string, IconSignature);
icons.set(SvgIconType.Search, IconSearch);
icons.set(SvgIconType.Settings, IconSettings);
icons.set(SvgIconType.SyncLeft, IconArrowLeftCircle);
icons.set(SvgIconType.SyncRight, IconArrowRightCircle);
icons.set(SvgIconType.ThreeDots, IconDots);
icons.set(SvgIconType.Unlink, IconUnlink);
icons.set(SvgIconType.ValueMapping, SvgIconValueMapping);
icons.set(SvgIconType.Variable, IconVariable);
icons.set(SvgIconType.WindowMaximize, IconArrowDownLeft);
icons.set(SvgIconType.WindowMinimize, IconArrowUpRight);
icons.set(SvgIconType.ZoomCancel, IconZoomCancel);
icons.set(SvgIconType.TemplatesEntity, SvgIconUnifiedInterfaces);
icons.set(SvgIconType.TemplateEntity, SvgIconUnifiedInterface);
icons.set(SvgIconType.AppsEntity, SvgIconApp);
icons.set(SvgIconType.AppEntity, SvgIconApp);
icons.set(SvgIconType.FieldMappingsEntity, SvgIconFieldMappings);
icons.set(SvgIconType.FieldMappingEntity, SvgIconFieldMapping);
icons.set(SvgIconType.FlowsEntity, SvgIconFlows);
icons.set(SvgIconType.FlowEntity, SvgIconFlow);
icons.set(SvgIconType.ActionsEntity, IconCircleArrowUpRight);
icons.set(SvgIconType.ActionEntity, IconCircleArrowUpRight);
icons.set(SvgIconType.DataSourcesEntity, SvgIconDataSources);
icons.set(SvgIconType.DataSourceEntity, IconDatabase);
icons.set(SvgIconType.DataLinkEntity, IconLine);
icons.set(SvgIconType.ObjectLinksEntity, SvgIconObjectLinks);
icons.set(SvgIconType.ObjectLinkEntity, SvgIconObjectLink);
icons.set(SvgIconType.UsersEntity, IconUsers);
icons.set(SvgIconType.UserEntity, IconUser);
icons.set(SvgIconType.AppEventSubscriptionsEntity, SvgIconAppEventSubscriptions);
icons.set(SvgIconType.AppEventSubscriptionEntity, IconBolt);
icons.set(SvgIconType.DataSchemasEntity, SvgIconDataSchemas);
icons.set(SvgIconType.DataSchemaEntity, SvgIconDataSchema);
icons.set(SvgIconType.CustomFieldsEntity, IconPlaylistAdd);
icons.set(SvgIconType.MonitoringEntity, IconChartHistogram);
icons.set(SvgIconType.ApisEntity, IconPlugConnected);
icons.set(SvgIconType.ApiEntity, IconPlugConnected);
icons.set(SvgIconType.LogEntity, IconHistory);
icons.set(SvgIconType.UdmActivities, IconActivity);
icons.set(SvgIconType.UdmCampains, IconSocial);
icons.set(SvgIconType.UdmCompanies, IconBuildingSkyscraper);
icons.set(SvgIconType.UdmContacts, IconNotebook);
icons.set(SvgIconType.UdmDeals, IconCoin);
icons.set(SvgIconType.UdmDealProducts, IconShoppingCart);
icons.set(SvgIconType.UdmProducts, IconShoppingCart);
icons.set(SvgIconType.UdmUsers, IconUsers);
icons.set(SvgIconType.UdmMeetings, IconCalendarEvent);
icons.set(SvgIconType.UdmTasks, IconChecklist);
icons.set(SvgIconType.UdmNotes, IconNotes);
icons.set(SvgIconType.UdmEmails, IconMail);
icons.set(SvgIconType.UDMEntity, IconStack2);
function getIconByType(type) {
  return icons.get(type) || IntegrationAppIcon;
}
function SvgIcon({
  type = SvgIconType.ChevronDown,
  ...props
}) {
  const Icon2 = getIconByType(type);
  const isAriaHidden = !props["aria-label"] && !props["aria-labelledby"];
  return /* @__PURE__ */ jsx(Icon2, { ...getIconProps(type), "aria-hidden": isAriaHidden, ...props });
}
function IntegrationAppIcon(props) {
  return /* @__PURE__ */ jsx("svg", { viewBox: "0 0 34 20", xmlns: "http://www.w3.org/2000/svg", ...props, children: /* @__PURE__ */ jsx(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m17 4.9-1.6 1.4v.1c1.4 1.2 2 1.8 2.3 2.5.2.7.2 1.5 0 2.3-.3.6-1 1.3-2.2 2.5L17 15l1.5-1.3c1.4-1.3 2-2 2.3-2.8.2-.6.2-1.3 0-2-.3-.7-1-1.4-2.3-2.7L17 4.9Zm0 0-2.6-2.4C13 1 12.4.5 11.5.2c-.7-.2-1.4-.2-2 0-.9.3-1.6 1-3 2.3l-4 3.8C1.1 7.6.5 8.3.2 9c-.3.7-.3 1.4 0 2 .2.8 1 1.5 2.3 2.8l4 3.8c1.4 1.3 2.1 2 3 2.2.6.3 1.3.3 2 0 .8-.2 1.5-.9 3-2.2l2.5-2.5 2.6 2.4c1.4 1.4 2 2 2.9 2.3.6.2 1.4.2 2 0 .9-.3 1.6-1 3-2.3l4-3.8c1.4-1.3 2-2 2.3-2.7.3-.7.3-1.4 0-2-.2-.8-1-1.5-2.3-2.8l-4-3.8C26 1 25.4.4 24.5.2c-.6-.3-1.3-.3-2 0-.8.2-1.5.9-3 2.2L17 4.9Zm-1.6 1.4a9.9 9.9 0 0 0-2.2 2.5c-.2.8-.2 1.6 0 2.3.3.7 1 1.3 2.2 2.5l-1 1c-1.4 1.4-2 2-2.9 2.3-.7.2-1.4.2-2 0-.9-.2-1.6-1-3-2.2l-.9-1c-1.4-1.2-2-1.9-2.3-2.7-.3-.6-.3-1.3 0-2 .2-.7 1-1.4 2.3-2.7l1-.9c1.3-1.3 2-2 2.8-2.2.7-.2 1.5-.2 2.1 0 .8.2 1.5.9 3 2.2l1 1Z"
    }
  ) });
}
function DefaultWorkspaceLogo(props) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      viewBox: "0 0 32 22",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      stroke: "currentColor",
      ...props,
      children: [
        /* @__PURE__ */ jsx("path", { d: "M9.5 2.5c1-1 2.5-1 3.5 0l7 7c1 1 1 2.5 0 3.5l-7 7c-1 1-2.5 1-3.5 0l-7-7c-1-1-1-2.5 0-3.5l7-7Z" }),
        /* @__PURE__ */ jsx("path", { d: "M18.5 2.5c1-1 2.5-1 3.5 0l7 7c1 1 1 2.5 0 3.5l-7 7c-1 1-2.5 1-3.5 0l-7-7c-1-1-1-2.5 0-3.5l7-7Z" })
      ]
    }
  );
}
function SvgIconSchemaTypeNumber(props) {
  return /* @__PURE__ */ jsx(
    "svg",
    {
      viewBox: "0 0 20 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...props,
      children: /* @__PURE__ */ jsx(
        "path",
        {
          d: "M5.56 13V8.292L4.48 8.952V7.888L5.56 7.24H6.544V13H5.56ZM7.90781 12.992V12.152L10.3718 9.956C10.5638 9.78533 10.6971 9.624 10.7718 9.472C10.8465 9.32 10.8838 9.17333 10.8838 9.032C10.8838 8.84 10.8425 8.66933 10.7598 8.52C10.6771 8.368 10.5625 8.248 10.4158 8.16C10.2718 8.072 10.1051 8.028 9.91581 8.028C9.71581 8.028 9.53848 8.07467 9.38381 8.168C9.23181 8.25867 9.11315 8.38 9.02781 8.532C8.94248 8.684 8.90248 8.848 8.90781 9.024H7.91581C7.91581 8.64 8.00115 8.30533 8.17181 8.02C8.34515 7.73467 8.58248 7.51333 8.88381 7.356C9.18781 7.19867 9.53848 7.12 9.93581 7.12C10.3038 7.12 10.6345 7.20267 10.9278 7.368C11.2211 7.53067 11.4518 7.75733 11.6198 8.048C11.7878 8.336 11.8718 8.668 11.8718 9.044C11.8718 9.31867 11.8345 9.54933 11.7598 9.736C11.6851 9.92267 11.5731 10.0947 11.4238 10.252C11.2771 10.4093 11.0945 10.584 10.8758 10.776L9.12781 12.324L9.03981 12.084H11.8718V12.992H7.90781ZM14.4814 13.108C14.1934 13.108 13.9214 13.052 13.6654 12.94C13.4094 12.828 13.1868 12.6693 12.9974 12.464C12.8108 12.2587 12.6761 12.016 12.5934 11.736L13.5254 11.484C13.5921 11.7133 13.7134 11.8907 13.8894 12.016C14.0681 12.1387 14.2641 12.1987 14.4774 12.196C14.6668 12.196 14.8361 12.152 14.9854 12.064C15.1348 11.976 15.2521 11.8573 15.3374 11.708C15.4228 11.556 15.4654 11.3867 15.4654 11.2C15.4654 10.9147 15.3734 10.676 15.1894 10.484C15.0054 10.292 14.7681 10.196 14.4774 10.196C14.3894 10.196 14.3028 10.208 14.2174 10.232C14.1348 10.256 14.0548 10.2893 13.9774 10.332L13.5374 9.572L15.4734 7.916L15.5574 8.148H12.8174V7.24H16.3494V8.152L14.7934 9.648L14.7854 9.364C15.1348 9.388 15.4321 9.48533 15.6774 9.656C15.9254 9.82667 16.1148 10.0467 16.2454 10.316C16.3788 10.5853 16.4454 10.88 16.4454 11.2C16.4454 11.5627 16.3561 11.888 16.1774 12.176C16.0014 12.464 15.7641 12.692 15.4654 12.86C15.1694 13.0253 14.8414 13.108 14.4814 13.108Z",
          fill: "currentColor"
        }
      )
    }
  );
}
function SvgIconSchemaTypeInteger(props) {
  return /* @__PURE__ */ jsx(SvgIconSchemaTypeNumber, { ...props });
}
function SvgIconSchemaTypeRequest(props) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      viewBox: "0 0 20 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...props,
      stroke: "currentColor",
      children: [
        /* @__PURE__ */ jsx("path", { d: "M3.33334 12C3.33334 12.9205 4.07953 13.6667 5 13.6667C5.92048 13.6667 6.66667 12.9205 6.66667 12C6.66667 11.0795 5.92048 10.3333 5.00001 10.3333C4.07953 10.3333 3.33334 11.0795 3.33334 12Z" }),
        /* @__PURE__ */ jsx("path", { d: "M15 6.16666L15 10.3333C15 10.7754 14.8244 11.1993 14.5118 11.5118C14.1993 11.8244 13.7754 12 13.3333 12L6.66667 12" }),
        /* @__PURE__ */ jsx("path", { d: "M12.5 8.66666L15 6.16666L17.5 8.66666" })
      ]
    }
  );
}
function SvgIconThreeDotsVertical(props) {
  return /* @__PURE__ */ jsx(
    "svg",
    {
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...props,
      children: /* @__PURE__ */ jsx(
        "path",
        {
          d: "M13.5 21a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm0-9a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm0-9a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z",
          fill: "currentColor"
        }
      )
    }
  );
}
function SvgIconArray(props) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      viewBox: "0 0 20 21",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...props,
      children: [
        /* @__PURE__ */ jsx("path", { d: "M6.5 5.6h9.2" }),
        /* @__PURE__ */ jsx("path", { d: "M7.5 10.6h9.2", strokeOpacity: ".5" }),
        /* @__PURE__ */ jsx("path", { d: "M6.5 15.6h9.2M3.2 5.6v0" }),
        /* @__PURE__ */ jsx("path", { d: "M4.2 10.6v0", strokeOpacity: ".5" }),
        /* @__PURE__ */ jsx("path", { d: "M3.2 15.6v0" })
      ]
    }
  );
}
function SvgIconLoader(props) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 100 100",
      preserveAspectRatio: "xMidYMid",
      fill: "currentColor",
      ...props,
      children: [
        /* @__PURE__ */ jsx("rect", { x: "46", y: "4", rx: "3.2", ry: "3.2", width: "8", height: "20", children: /* @__PURE__ */ jsx(
          "animate",
          {
            attributeName: "opacity",
            values: "1;0",
            keyTimes: "0;1",
            dur: "1.4285714285714284s",
            begin: "-1.2499999999999998s",
            repeatCount: "indefinite"
          }
        ) }),
        /* @__PURE__ */ jsx(
          "rect",
          {
            x: "46",
            y: "4",
            rx: "3.2",
            ry: "3.2",
            width: "8",
            height: "20",
            transform: "rotate(45 50 50)",
            children: /* @__PURE__ */ jsx(
              "animate",
              {
                attributeName: "opacity",
                values: "1;0",
                keyTimes: "0;1",
                dur: "1.4285714285714284s",
                begin: "-1.0714285714285714s",
                repeatCount: "indefinite"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          "rect",
          {
            x: "46",
            y: "4",
            rx: "3.2",
            ry: "3.2",
            width: "8",
            height: "20",
            transform: "rotate(90 50 50)",
            children: /* @__PURE__ */ jsx(
              "animate",
              {
                attributeName: "opacity",
                values: "1;0",
                keyTimes: "0;1",
                dur: "1.4285714285714284s",
                begin: "-0.8928571428571428s",
                repeatCount: "indefinite"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          "rect",
          {
            x: "46",
            y: "4",
            rx: "3.2",
            ry: "3.2",
            width: "8",
            height: "20",
            transform: "rotate(135 50 50)",
            children: /* @__PURE__ */ jsx(
              "animate",
              {
                attributeName: "opacity",
                values: "1;0",
                keyTimes: "0;1",
                dur: "1.4285714285714284s",
                begin: "-0.7142857142857142s",
                repeatCount: "indefinite"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          "rect",
          {
            x: "46",
            y: "4",
            rx: "3.2",
            ry: "3.2",
            width: "8",
            height: "20",
            transform: "rotate(180 50 50)",
            children: /* @__PURE__ */ jsx(
              "animate",
              {
                attributeName: "opacity",
                values: "1;0",
                keyTimes: "0;1",
                dur: "1.4285714285714284s",
                begin: "-0.5357142857142857s",
                repeatCount: "indefinite"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          "rect",
          {
            x: "46",
            y: "4",
            rx: "3.2",
            ry: "3.2",
            width: "8",
            height: "20",
            transform: "rotate(225 50 50)",
            children: /* @__PURE__ */ jsx(
              "animate",
              {
                attributeName: "opacity",
                values: "1;0",
                keyTimes: "0;1",
                dur: "1.4285714285714284s",
                begin: "-0.3571428571428571s",
                repeatCount: "indefinite"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          "rect",
          {
            x: "46",
            y: "4",
            rx: "3.2",
            ry: "3.2",
            width: "8",
            height: "20",
            transform: "rotate(270 50 50)",
            children: /* @__PURE__ */ jsx(
              "animate",
              {
                attributeName: "opacity",
                values: "1;0",
                keyTimes: "0;1",
                dur: "1.4285714285714284s",
                begin: "-0.17857142857142855s",
                repeatCount: "indefinite"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          "rect",
          {
            x: "46",
            y: "4",
            rx: "3.2",
            ry: "3.2",
            width: "8",
            height: "20",
            transform: "rotate(315 50 50)",
            children: /* @__PURE__ */ jsx(
              "animate",
              {
                attributeName: "opacity",
                values: "1;0",
                keyTimes: "0;1",
                dur: "1.4285714285714284s",
                begin: "0s",
                repeatCount: "indefinite"
              }
            )
          }
        )
      ]
    }
  );
}
function SvgIconValueMapping(props) {
  return /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      strokeWidth: "2",
      stroke: "currentColor",
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      ...props,
      children: [
        /* @__PURE__ */ jsx("path", { d: "M18 15l3 -3l-3 -3" }),
        /* @__PURE__ */ jsx("circle", { cx: "5", cy: "12", r: "2" }),
        /* @__PURE__ */ jsx("path", { d: "M7 12h14" })
      ]
    }
  );
}
function SvgIconAlertTriangle(props) {
  return /* @__PURE__ */ jsx(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      stroke: "currentColor",
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      ...props,
      children: /* @__PURE__ */ jsx(
        "path",
        {
          d: "M12 9v2m0 4v0m-7 4h14a2 2 0 0 0 1.8-2.8L13.8 4a2 2 0 0 0-3.6 0l-7 12.3A2 2 0 0 0 4.8 19",
          transform: "translate(0,1)"
        }
      )
    }
  );
}
function SvgIconReset(props) {
  const style = {
    transform: "rotate(135deg)",
    ...props?.style ?? {}
  };
  return /* @__PURE__ */ jsx(IconRotate, { ...props, style });
}

export { SvgIcon };

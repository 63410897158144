import { Flow } from '@integration-app/sdk'
import { useDebounceValue } from 'usehooks-ts'

import { SelectItemType, Combobox, ComboboxProps } from 'components/Select'

import { OptionItem } from './components'
import useIntegrationElement from '../hooks/useIntegrationElement'

export function FlowKeyComboboxSelect({
  value,
  onChange,
  name,
  ...props
}: Omit<ComboboxProps, 'items' | 'value' | 'onChange'> & {
  value?: string
  onChange: (value?: string) => void
  name?: string
}) {
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    150,
  )

  const { items } = useIntegrationElement<Flow>({
    route: 'flows',
  })

  const options = items?.map((flow) => ({
    value: flow.key,
    label: flow.name,
  }))

  const filteredOptions = options?.filter((o) => {
    return [o.label, o.value]
      .map((x) => x.toLowerCase())
      .some((x) => x.includes(debouncedSearchValue.toLowerCase()))
  })
  const selectedOption = options?.find((o) => o.value === value)

  function handleChange(item: SelectItemType) {
    onChange(item?.value)
  }

  return (
    <Combobox
      items={filteredOptions}
      value={selectedOption}
      onValueChange={handleChange}
      placeholder={'Select a flow'}
      onSearchInputChange={setDebouncedSearchValue}
      TriggerTextComponent={(props) => (
        <OptionItem asTrigger name={name} {...props} />
      )}
      ItemTextComponent={(props) => <OptionItem {...props} />}
      {...props}
    />
  )
}

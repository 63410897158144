// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '9770d313a7634213fa373028972698ed';
const css =`._fieldType_18283_1{align-items:center;border-radius:.25rem;color:#83ad09;cursor:pointer;display:inline-flex;padding-left:.375rem;padding-right:.375rem}button._fieldType_18283_1{margin-top:-1px}button._fieldType_18283_1:focus{outline:2px solid transparent;outline-offset:2px}button._fieldType_18283_1:focus,button._fieldType_18283_1:hover{--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity,1))}._readOnly_18283_1{cursor:default}._fieldType_18283_1 svg{stroke:#000;stroke-width:1.5;height:.75rem;margin-left:.25rem;margin-top:.2rem;width:.75rem}._fieldType__string_18283_1{color:#83ad09}._fieldType__number_18283_1{color:#5d7a06}._fieldType__boolean_18283_1{color:#c75300}._fieldType__object_18283_1{color:#fabb66}._fieldType__connection_18283_1{color:#0c367a}._fieldType__request_18283_1{color:#0b97e3}._fieldType__array_18283_1{color:#7e6edb}._defaultMenu_18283_1{cursor:pointer;padding:0}._defaultMenuList_18283_1:focus{outline:2px solid transparent;outline-offset:2px}._defaultMenuItem_18283_1{--tw-border-opacity:1;align-items:center;border-color:rgb(221 221 221/var(--tw-border-opacity,1));border-top-width:1px;display:flex;flex-direction:row;margin:0;padding:.5rem .75rem;text-align:left;width:100%}._defaultMenuItem_18283_1:first-child{border-top-width:0}._defaultMenuItem_18283_1:hover{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity,1))}._defaultMenuItem_18283_1 svg{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity,1));height:1.25rem;margin-right:.5rem;transform:translateY(.06rem);width:1.25rem}._defaultMenuItemActive_18283_1{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity,1))}`;
const styles = {
    get ['fieldType']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType_18283_1'; },
    get ['readOnly']() { injectStyles(key, css, options, undefined, undefined);  return '_readOnly_18283_1'; },
    get ['fieldType__string']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__string_18283_1'; },
    get ['fieldTypeString']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__string_18283_1'; },
    get ['fieldType__number']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__number_18283_1'; },
    get ['fieldTypeNumber']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__number_18283_1'; },
    get ['fieldType__boolean']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__boolean_18283_1'; },
    get ['fieldTypeBoolean']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__boolean_18283_1'; },
    get ['fieldType__object']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__object_18283_1'; },
    get ['fieldTypeObject']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__object_18283_1'; },
    get ['fieldType__connection']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__connection_18283_1'; },
    get ['fieldTypeConnection']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__connection_18283_1'; },
    get ['fieldType__request']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__request_18283_1'; },
    get ['fieldTypeRequest']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__request_18283_1'; },
    get ['fieldType__array']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__array_18283_1'; },
    get ['fieldTypeArray']() { injectStyles(key, css, options, undefined, undefined);  return '_fieldType__array_18283_1'; },
    get ['defaultMenu']() { injectStyles(key, css, options, undefined, undefined);  return '_defaultMenu_18283_1'; },
    get ['defaultMenuList']() { injectStyles(key, css, options, undefined, undefined);  return '_defaultMenuList_18283_1'; },
    get ['defaultMenuItem']() { injectStyles(key, css, options, undefined, undefined);  return '_defaultMenuItem_18283_1'; },
    get ['defaultMenuItemActive']() { injectStyles(key, css, options, undefined, undefined);  return '_defaultMenuItemActive_18283_1'; },
    inject(shadowRoot) { injectStyles(key, css, options, undefined, shadowRoot); }
};
export { styles, css, key };
export default styles;

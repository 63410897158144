"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { isCustomFieldValue } from '../../../ComboBox/options-factories/custom-field.mjs';
import { getTagString, regExpTag, TAG_MARK_START, TAG_MARK_END } from './tag-template-composition.mjs';

function getTemplateFromTplValue(value) {
  if (typeof value === "string") {
    return value;
  }
  if (isValueTemplate(value)) {
    return value.$tpl.template;
  }
  return "";
}
function getValuesFromTplValue(value) {
  if (isValueTemplate(value)) {
    return value.$tpl.values;
  }
  return {};
}
function transformValueToTpl(value) {
  if (value === void 0 || value === null) {
    return "";
  }
  if (isCustomFieldValue(value)) {
    return "";
  }
  if (typeof value === "string") {
    return value;
  }
  if (typeof value === "number") {
    return String(value);
  }
  if (isValueTemplate(value)) {
    return value;
  }
  return {
    $tpl: {
      template: "{10000000}",
      values: {
        1e7: value
      }
    }
  };
}
const isValueTemplate = (value) => {
  return value?.$tpl !== void 0;
};
function transformTplToValue(template, values) {
  if (template === "" || template === void 0 || template === null) {
    return void 0;
  }
  const valuesExistInTemplate = getValuesExistInTemplate(template, values);
  const cleanedTemplate = getTemplateWithExistingTags(
    template,
    valuesExistInTemplate
  );
  if (!strContainsTagChunk(cleanedTemplate)) {
    return cleanedTemplate ? cleanedTemplate : void 0;
  }
  if (Object.keys(valuesExistInTemplate).length === 1 && isTagChunk(cleanedTemplate)) {
    return Object.values(valuesExistInTemplate)[0];
  }
  return {
    $tpl: {
      template: cleanedTemplate,
      values: valuesExistInTemplate
    }
  };
}
function splitStringToChunks(line) {
  return line.split(regExpTag).filter((l) => l !== "");
}
function isTagChunk(chunk) {
  return strContainsTagChunk(chunk) && chunk.startsWith(TAG_MARK_START) && chunk.endsWith(TAG_MARK_END);
}
function isValueEmpty(value) {
  return value === void 0 || value === null;
}
function strContainsTagChunk(str) {
  return str ? !!str.match(regExpTag) : false;
}
function getTagChunkIdentifier(chunk) {
  return chunk.substring(1, chunk.length - 1);
}
function getValuesExistInTemplate(template, values) {
  return Object.keys(values).reduce((acc, valueId) => {
    return !isValueEmpty(values[valueId]) && template.includes(getTagString(valueId)) ? { ...acc, [valueId]: values[valueId] } : acc;
  }, {});
}
function getTemplateWithExistingTags(template, values) {
  return splitStringToChunks(template ?? "").map((string) => {
    if (isTagChunk(string) && isValueEmpty(values[getTagChunkIdentifier(string)])) {
      return "";
    }
    return string;
  }).join("");
}

export { getTagChunkIdentifier, getTemplateFromTplValue, getTemplateWithExistingTags, getValuesExistInTemplate, getValuesFromTplValue, isTagChunk, isValueTemplate, splitStringToChunks, transformTplToValue, transformValueToTpl };

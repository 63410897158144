import { ReactNode } from 'react'
import { SvgIcon, SvgIconType } from '@integration-app/react'

import { Tooltip } from 'components/Tooltip'
import { Icon } from 'ui-kit/icon'
import {
  IconButtonProps as UiKitIconButtonProps,
  IconButton as UiKitIconButton,
} from 'ui-kit/icon-button'

export type IconButtonProps = UiKitIconButtonProps & {
  tooltip: ReactNode
  loading?: boolean
}

// Simple implementation of `IconButton`
// that forces to use tooltip and provides ARIA compatibility
export function IconButton({
  children,
  tooltip,
  size,
  loading,
  ...props
}: IconButtonProps) {
  return (
    <Tooltip tooltip={tooltip}>
      <UiKitIconButton {...props} size={size}>
        <Icon size={size}>
          {loading ? <SvgIcon type={SvgIconType.Loader} /> : children}
        </Icon>
      </UiKitIconButton>
    </Tooltip>
  )
}

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { useState, useLayoutEffect } from 'react';
import { getRefElement } from '../getRefElement.mjs';

function useResizeObserver(ref, callback) {
  const [prevWidth, setPrevWidth] = useState(0);
  const [prevHeight, setPrevHeight] = useState(0);
  useLayoutEffect(() => {
    const target = getRefElement(ref);
    if (!target) return () => {
    };
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      const widthResized = width !== prevWidth;
      const heightResized = height !== prevHeight;
      if (widthResized || heightResized) {
        callback({
          resized: {
            width: widthResized,
            height: heightResized
          },
          size: {
            width,
            height
          }
        });
        if (widthResized) {
          setPrevWidth(width);
        }
        if (heightResized) {
          setPrevHeight(height);
        }
      }
    });
    resizeObserver.observe(target);
    return () => resizeObserver.unobserve(target);
  }, [ref]);
}

export { useResizeObserver as default };

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

var SvgIconType = /* @__PURE__ */ ((SvgIconType2) => {
  SvgIconType2["Add"] = "Add";
  SvgIconType2["AlertCircle"] = "AlertCircle";
  SvgIconType2["AlertOctagon"] = "AlertOctagon";
  SvgIconType2["AlertTriangle"] = "AlertTriangle";
  SvgIconType2["AlignLeft"] = "AlignLeft";
  SvgIconType2["AppIcon"] = "AppIcon";
  SvgIconType2["Archive"] = "Archive";
  SvgIconType2["Array"] = "Array";
  SvgIconType2["ArrowRight"] = "ArrowRight";
  SvgIconType2["Back"] = "Back";
  SvgIconType2["Braces"] = "Braces";
  SvgIconType2["Cancel"] = "Cancel";
  SvgIconType2["Check"] = "Check";
  SvgIconType2["Checks"] = "Checks";
  SvgIconType2["ChevronDown"] = "ChevronDown";
  SvgIconType2["ChevronLeft"] = "ChevronLeft";
  SvgIconType2["ChevronRight"] = "ChevronRight";
  SvgIconType2["ChevronUp"] = "ChevronUp";
  SvgIconType2["Close"] = "Close";
  SvgIconType2["Code"] = "Code";
  SvgIconType2["Copy"] = "Copy";
  SvgIconType2["DefaultWorkspaceIcon"] = "DefaultWorkspaceIcon";
  SvgIconType2["Delete"] = "Delete";
  SvgIconType2["DotsVertical"] = "DotsVertical";
  SvgIconType2["Edit"] = "Edit";
  SvgIconType2["ExternalLink"] = "ExternalLink";
  SvgIconType2["Eye"] = "Eye";
  SvgIconType2["EyeOff"] = "EyeOff";
  SvgIconType2["Function"] = "Function";
  SvgIconType2["HamburgerMenu"] = "HamburgerMenu";
  SvgIconType2["InfoCircle"] = "InfoCircle";
  SvgIconType2["Link"] = "Link";
  SvgIconType2["ListOption"] = "ListOption";
  SvgIconType2["Loader"] = "Loader";
  SvgIconType2["Lookup"] = "Lookup";
  SvgIconType2["PlayerPlay"] = "PlayerPlay";
  SvgIconType2["PlayerPlayFilled"] = "PlayerPlayFilled";
  SvgIconType2["QuestionMark"] = "QuestionMark";
  SvgIconType2["Refresh"] = "Refresh";
  SvgIconType2["Reset"] = "Reset";
  SvgIconType2["SchemaType_array"] = "SchemaTypeArray";
  SvgIconType2["SchemaType_boolean"] = "SchemaTypeBoolean";
  SvgIconType2["SchemaType_connection"] = "SchemaTypeConnection";
  SvgIconType2["SchemaType_integer"] = "SchemaTypeInteger";
  SvgIconType2["SchemaType_number"] = "SchemaTypeNumber";
  SvgIconType2["SchemaType_object"] = "SchemaTypeObject";
  SvgIconType2["SchemaType_request"] = "SchemaTypeRequest";
  SvgIconType2["SchemaType_string"] = "SchemaTypeString";
  SvgIconType2["Search"] = "Search";
  SvgIconType2["Settings"] = "Settings";
  SvgIconType2["SyncLeft"] = "SyncLeft";
  SvgIconType2["SyncRight"] = "SyncRight";
  SvgIconType2["ThreeDots"] = "ThreeDots";
  SvgIconType2["Unlink"] = "Unlink";
  SvgIconType2["ValueMapping"] = "ValueMapping";
  SvgIconType2["Variable"] = "Variable";
  SvgIconType2["WindowMaximize"] = "WindowMaximize";
  SvgIconType2["WindowMinimize"] = "WindowMinimize";
  SvgIconType2["ZoomCancel"] = "ZoomCancel";
  SvgIconType2["ActionEntity"] = "Action";
  SvgIconType2["ActionsEntity"] = "Actions";
  SvgIconType2["TemplatesEntity"] = "Templates";
  SvgIconType2["TemplateEntity"] = "Template";
  SvgIconType2["AppsEntity"] = "Apps";
  SvgIconType2["AppEntity"] = "App";
  SvgIconType2["ApisEntity"] = "Apis";
  SvgIconType2["ApiEntity"] = "Api";
  SvgIconType2["FieldMappingsEntity"] = "FieldMappings";
  SvgIconType2["FieldMappingEntity"] = "FieldMapping";
  SvgIconType2["FlowsEntity"] = "Flows";
  SvgIconType2["FlowEntity"] = "Flow";
  SvgIconType2["DataSourcesEntity"] = "DataSources";
  SvgIconType2["DataSourceEntity"] = "DataSource";
  SvgIconType2["ObjectLinksEntity"] = "ObjectLinks";
  SvgIconType2["ObjectLinkEntity"] = "ObjectLink";
  SvgIconType2["UsersEntity"] = "UsersEntity";
  SvgIconType2["UserEntity"] = "UserEntity";
  SvgIconType2["DataSchemasEntity"] = "DataSchemas";
  SvgIconType2["DataSchemaEntity"] = "DataSchema";
  SvgIconType2["DataLinkEntity"] = "DataLink";
  SvgIconType2["AppEventSubscriptionsEntity"] = "AppEventSubscriptions";
  SvgIconType2["AppEventSubscriptionEntity"] = "AppEventSubscription";
  SvgIconType2["CustomFieldsEntity"] = "CustomFields";
  SvgIconType2["MonitoringEntity"] = "Monitoring";
  SvgIconType2["LogEntity"] = "LogEntity";
  SvgIconType2["UDMEntity"] = "UDMEntity";
  SvgIconType2["UdmActivities"] = "UdmActivities";
  SvgIconType2["UdmCampains"] = "UdmCampains";
  SvgIconType2["UdmCompanies"] = "UdmCompanies";
  SvgIconType2["UdmContacts"] = "UdmContacts";
  SvgIconType2["UdmDeals"] = "UdmDeals";
  SvgIconType2["UdmDealProducts"] = "UdmDealProducts";
  SvgIconType2["UdmProducts"] = "UdmProducts";
  SvgIconType2["UdmUsers"] = "UdmUsers";
  SvgIconType2["UdmMeetings"] = "UdmMeetings";
  SvgIconType2["UdmTasks"] = "UdmTasks";
  SvgIconType2["UdmNotes"] = "UdmNotes";
  SvgIconType2["UdmEmails"] = "UdmEmails";
  return SvgIconType2;
})(SvgIconType || {});

export { SvgIconType };

import { ComponentProps, ReactElement } from 'react'
import { RecipeVariantProps } from '@pandacss/types'

import { getDocRelativeLink } from 'components/Docs/DocLinksGrid/helpers'
import { Link } from 'components/Link'
import { getArticle } from 'routes/Docs/helpers/articleList'
import { sva, cx } from 'styled-system/css'
import { Icon } from 'ui-kit/icon'

import { DocTagBadge } from './DocTag'

const linkRecipe = sva({
  className: 'docLink',
  slots: ['root', 'icon'],
  base: {
    root: {
      colorPalette: 'primary',
      display: 'inline-flex',
      alignItems: 'center',
      lineHeight: 'none',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      gap: 0.5,
      _hover: {
        textDecoration: 'underline',
      },
    },
    icon: {
      alignSelf: 'center',
    },
  },
  variants: {
    variant: {
      ajax: {
        root: {
          textDecorationLine: 'underline',
          textDecoration: 'dashed',
        },
      },
    },
  },
})

export type DocLinkProps = Omit<ComponentProps<typeof Link>, 'to'> &
  RecipeVariantProps<typeof linkRecipe> & {
    path: string
    showTag?: boolean
    leftIcon?: ReactElement
    rightIcon?: ReactElement
  }

export default function DocLink({
  path,
  showTag,
  leftIcon,
  rightIcon,
  children,
  className,
  variant,
  ...props
}: DocLinkProps) {
  const to = getDocRelativeLink(path)
  const linkRecipeClassnames = linkRecipe({ variant })

  return (
    <Link
      to={to}
      className={cx(linkRecipeClassnames.root, className)}
      {...props}
    >
      {!!leftIcon && (
        <Icon size={'sm'} className={linkRecipeClassnames.icon}>
          {leftIcon}
        </Icon>
      )}
      {children || getArticle(path)?.title}
      {!!rightIcon && (
        <Icon size={'sm'} className={linkRecipeClassnames.icon}>
          {rightIcon}
        </Icon>
      )}
      {showTag && <DocTagBadge />}
    </Link>
  )
}

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import Fuse from 'fuse';

function filterOptionsByLabel(options, searchString, fuzzy = true) {
  if (!searchString || typeof searchString !== "string") {
    return options;
  }
  return new Fuse(options, {
    shouldSort: true,
    keys: ["searchLabel", "label"],
    threshold: fuzzy ? 0.3 : 0
    // 0 = exact match, 1 = match all
  }).search(searchString.trim()).map(({ item }) => item);
}

export { filterOptionsByLabel };

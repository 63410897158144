import {
  Children,
  cloneElement,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import useEventStopPropagation, {
  EventHandlerFunctionType,
} from '@integration-app/ui/hooks/useEventStopPropagation'
import { TbChecks } from 'react-icons/tb'
import { useCopyToClipboard as useCopyToClipboardHook } from 'usehooks-ts'

import { Tooltip, TooltipProps } from 'components/Tooltip'
import { Icon } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'

const DEFAULT_TIMEOUT = 2000

interface ICopyToClipboard {
  children: ReactElement<any, string | JSXElementConstructor<any>>
  value: string
  confirmationText?: ReactNode
  timeout?: number
  onClick?: EventHandlerFunctionType
}

export function useCopyToClipboard(timeout: number = DEFAULT_TIMEOUT) {
  const [copied, setCopied] = useState(false)
  const [, copy] = useCopyToClipboardHook()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (copied) {
      timeoutId = setTimeout(() => setCopied(false), timeout)
    }
    return () => timeoutId && clearTimeout(timeoutId)
  }, [copied])

  async function copyToClipboard(value: string) {
    await copy(value)
    setCopied(true)
  }

  return {
    copyToClipboard,
    copied,
  }
}

export default function CopyToClipboard({
  children,
  value,
  confirmationText = <DefaultConfirmationText />,
  timeout = DEFAULT_TIMEOUT,
  onClick,
  ...props
}: ICopyToClipboard) {
  const { copied, copyToClipboard } = useCopyToClipboard(timeout)

  const handleOnClick = useEventStopPropagation(async (event) => {
    // useEventStopPropagation doesn't prevent default event behavior when click is on <a> tag
    // so we need to do it manually
    event?.preventDefault()

    await copyToClipboard(value)
    onClick?.(event)

    return false
  })

  const passedProps = {
    onClick: handleOnClick,
    ...props,
  } as Record<any, any>

  if (copied) {
    passedProps.children = confirmationText
  }

  return cloneElement(Children.only(children), passedProps)
}

export function CopyToClipboardTooltip({
  tooltip = <DefaultConfirmationText />,
  ...props
}: Omit<TooltipProps, 'children' | 'tooltip'> &
  Pick<Partial<TooltipProps>, 'tooltip'>) {
  return (
    <Tooltip
      tooltip={tooltip}
      positioning={{ placement: 'right' }}
      lazyMount
      unmountOnExit
      usePortal
      {...props}
    >
      <Text
        as='span'
        width='0'
        overflow='hidden'
        clip='rect(0, 0, 0, 0)'
        whiteSpace='nowrap'
      ></Text>
    </Tooltip>
  )
}

function DefaultConfirmationText() {
  return (
    <>
      Copied{' '}
      <Icon>
        <TbChecks />
      </Icon>
    </>
  )
}

import { ErrorData } from '@integration-app/sdk'
import { toHeaderCase } from 'js-convert-case'

import { getArticle } from 'routes/Docs/helpers/articleList'
import { HStack } from 'styled-system/jsx'

import { ErrorItem } from './ErrorItem'
import {
  getErrorAdditionalData,
  hasErrorAdditionalData,
  hasErrorDocsLink,
} from './helpers'

export function ErrorBadge({ error }: { error: ErrorData }) {
  if (!(error.type || error.key)) return null

  const badges = [error.type, error.key].filter(Boolean).map(toHeaderCase)

  return (
    <HStack marginInlineStart={'-1'}>
      {badges.map((badge) => (
        <ErrorItem.Badge key={badge}>{badge}</ErrorItem.Badge>
      ))}
    </HStack>
  )
}

export function ErrorJSON({ error }: { error: ErrorData }) {
  if (!hasErrorAdditionalData(error)) return null

  return <ErrorItem.JSONViewer json={getErrorAdditionalData(error)} />
}

export function ErrorDocsLink({ error }: { error: ErrorData }) {
  if (!hasErrorDocsLink(error)) return null

  const docLink = 'https://console.integration.app/docs/errors/' + error.doc
  const docTitle = `How to resolve this issue: ${
    getArticle('monitoring/activity-log/errors/' + error.doc)?.title
  }`

  return <ErrorItem.DocLink to={docLink}>{docTitle}</ErrorItem.DocLink>
}

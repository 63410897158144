"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { forwardRef, Children } from 'react';
import { clsx } from 'clsx';
import { SvgIconType } from '../SvgIcon/svg-icon-type.mjs';
import useEventStopPropagation from '../../helpers/hooks/useEventStopPropagation.mjs';
import { SvgIcon } from '../SvgIcon/index.mjs';
import { Tooltip } from '../Tooltip/index.mjs';
import classes from './styles.module.css.mjs';
export { FieldControlPanel } from './FieldControlPanel/index.mjs';

function RowRoot({
  children,
  className,
  propertiesComponent,
  embedded,
  short
}) {
  const childrenList = Array.isArray(children) ? children : [];
  const nestedPropertiesComponent = childrenList.find(
    (component) => component?.type === RowProperties
  );
  const nestedActionBarComponent = childrenList.find(
    (component) => component?.type === ActionBar
  );
  const childComponents = !childrenList.length ? children : childrenList.filter(
    /* FIXME: strictNullCheck temporary fix */
    // @ts-expect-error TS(2345): Argument of type 'string | JSXElementConstructor<a... Remove this comment to see the full error message
    (component) => ![RowProperties, ActionBar].includes(component?.type)
  );
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: clsx(
        classes.rowWrapper,
        embedded && classes.rowWrapperEmbedded
      ),
      children: [
        /* @__PURE__ */ jsxs(
          "div",
          {
            className: clsx(
              classes.row,
              embedded && classes.rowEmbedded,
              short && classes.rowShort,
              className
            ),
            children: [
              childComponents,
              nestedActionBarComponent
            ]
          }
        ),
        propertiesComponent,
        nestedPropertiesComponent
      ]
    }
  );
}
const RowProperties = forwardRef(
  ({ children, className, expanded, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "div",
      {
        className: clsx(
          classes.rowProperties,
          expanded && classes.rowPropertiesExpanded,
          className
        ),
        ...props,
        ref,
        children
      }
    );
  }
);
RowProperties.displayName = "RowProperties";
const Row = Object.assign(RowRoot, {
  Properties: RowProperties
});
const PropertyRoot = forwardRef(({ children, className, ...props }, ref) => {
  return /* @__PURE__ */ jsx("span", { className: classes.propertyWrapper, children: /* @__PURE__ */ jsx("span", { className: clsx(classes.property, className), ...props, ref, children }) });
});
PropertyRoot.displayName = "PropertyRoot";
const PropertyName = forwardRef(({ children, className, ...props }, ref) => {
  return /* @__PURE__ */ jsx(
    "span",
    {
      className: clsx(classes.propertyName, className),
      ...props,
      ref,
      children
    }
  );
});
PropertyName.displayName = "PropertyName";
const PropertyValue = forwardRef(({ children, className, ...props }, ref) => {
  return /* @__PURE__ */ jsx(
    "span",
    {
      className: clsx(classes.propertyValue, className),
      ...props,
      ref,
      children
    }
  );
});
PropertyValue.displayName = "PropertyValue";
const Property = Object.assign(PropertyRoot, {
  Name: PropertyName,
  Value: PropertyValue
});
const CellRoot = forwardRef(
  ({ children, disabled, grow, shrink, width, className, ...props }, ref) => {
    const style = width ? { flexBasis: width } : void 0;
    return /* @__PURE__ */ jsx(
      "div",
      {
        className: clsx(
          classes.cell,
          grow && classes.cellGrow,
          shrink && classes.cellShrink,
          disabled && classes.cellDisabled,
          className
        ),
        "aria-disabled": disabled,
        "aria-readonly": disabled,
        style,
        ...props,
        ref,
        children
      }
    );
  }
);
CellRoot.displayName = "CellRoot";
const CellName = forwardRef(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(Cell, { className: clsx(classes.cellName, className), ...props, ref, children });
  }
);
CellName.displayName = "CellName";
const CellCombobox = forwardRef(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      Cell,
      {
        className: clsx(classes.cellCombobox, className),
        ...props,
        ref,
        children
      }
    );
  }
);
CellCombobox.displayName = "CellCombobox";
const CellValue = forwardRef(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(Cell, { className: clsx(classes.cellValue, className), ...props, ref, children });
  }
);
CellValue.displayName = "CellValue";
const CellDataInput = CellCombobox;
CellDataInput.displayName = "CellDataInput";
const CellInput = forwardRef(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(Cell, { className: clsx(classes.cellInput, className), ...props, ref, children });
  }
);
CellInput.displayName = "CellInput";
const CellIcon = forwardRef(
  ({ type, className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(Cell, { className: clsx(classes.cellIcon, className), ...props, ref, children: /* @__PURE__ */ jsx(SvgIcon, { type }) });
  }
);
CellIcon.displayName = "CellIcon";
const Cell = Object.assign(CellRoot, {
  Name: CellName,
  Combobox: CellCombobox,
  // @deprecated
  DataInput: CellDataInput,
  Input: CellInput,
  Tag: CellCombobox,
  Icon: CellIcon,
  Value: CellValue
});
const Title = forwardRef(
  ({
    children,
    required,
    hasValue,
    hasError,
    leftSlot,
    typeSelectorSlot,
    rightSlot,
    ...props
  }, ref) => {
    const childrenAsText = Children.toArray(children).reduce(
      (acc, el) => {
        return acc + (typeof el === "string" ? el : "");
      },
      ""
    );
    return /* @__PURE__ */ jsxs("div", { className: classes.title, ...props, ref, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.title_leftSide, children: [
        leftSlot && /* @__PURE__ */ jsx("div", { children: leftSlot }),
        /* @__PURE__ */ jsx("div", { className: classes.title_title, title: childrenAsText, children }),
        rightSlot && /* @__PURE__ */ jsx(Fragment, { children: typeSelectorSlot })
      ] }),
      /* @__PURE__ */ jsxs("div", { className: classes.title_rightSide, children: [
        rightSlot && /* @__PURE__ */ jsx(Fragment, { children: rightSlot }),
        hasError && /* @__PURE__ */ jsx(
          "span",
          {
            className: clsx(classes.title_dot, classes.title_dot__error)
          }
        ),
        required && !hasError && !hasValue && /* @__PURE__ */ jsx(Tooltip, { tooltip: "Field is required", children: /* @__PURE__ */ jsx(
          "span",
          {
            className: clsx(classes.title_dot, classes.title_dot__required)
          }
        ) })
      ] })
    ] });
  }
);
Title.displayName = "Title";
const Description = forwardRef(({ children, ...props }, ref) => {
  return /* @__PURE__ */ jsx("div", { className: classes.description, ...props, ref, children });
});
Description.displayName = "Description";
const ValueRoot = forwardRef(
  ({ children, ...props }, ref) => {
    return /* @__PURE__ */ jsx("div", { className: classes.cellValue, ...props, ref, children });
  }
);
ValueRoot.displayName = "Value";
const ValueCombobox = forwardRef(({ children, ...props }, ref) => {
  return /* @__PURE__ */ jsx(ValueRoot, { className: classes.cellValueCombobox, ...props, ref, children });
});
ValueCombobox.displayName = "ValueCombobox";
const Value = Object.assign(ValueRoot, {
  Combobox: ValueCombobox,
  Tag: ValueCombobox,
  Inline: ValueRoot
});
const ActionBarRoot = forwardRef(({ children, className, ...props }, ref) => {
  return /* @__PURE__ */ jsx("div", { className: clsx(classes.actionBar, className), ...props, ref, children });
});
ActionBarRoot.displayName = "ActionBarRoot";
const ActionBarGenericButton = forwardRef(({ className, iconType, tooltip, onClick, ...props }, ref) => {
  const handleOnClick = useEventStopPropagation(
    (e) => onClick?.(e)
  );
  const button = /* @__PURE__ */ jsx(
    "button",
    {
      type: "button",
      className: clsx(classes.actionBarButton, className),
      onClick: handleOnClick,
      ...props,
      ref,
      children: iconType && /* @__PURE__ */ jsx(SvgIcon, { type: iconType })
    }
  );
  if (tooltip) {
    return /* @__PURE__ */ jsx(Tooltip, { tooltip, children: button });
  }
  return button;
});
ActionBarGenericButton.displayName = "ActionBarGenericButton";
const ActionBarDeleteButton = forwardRef(({ ...props }, ref) => {
  return /* @__PURE__ */ jsx(
    ActionBarGenericButton,
    {
      iconType: SvgIconType.Close,
      tooltip: "Delete",
      "aria-label": "Delete",
      ...props,
      ref
    }
  );
});
ActionBarDeleteButton.displayName = "ActionBarDeleteButton";
const ActionBar = Object.assign(ActionBarRoot, {
  GenericButton: ActionBarGenericButton,
  DeleteButton: ActionBarDeleteButton
});
const BASE_LEVEL = 1;
function NestedLevel({ level = BASE_LEVEL, children }) {
  if (level === BASE_LEVEL - 1) {
    return children;
  }
  return /* @__PURE__ */ jsx("div", { className: clsx(classes.nestedLevel), children: /* @__PURE__ */ jsx(NestedLevel, { level: level - 1, children }) });
}
function DrilldownBlockContentWrapper({ children }) {
  return /* @__PURE__ */ jsx("div", { className: classes.drilldownWrapper, children });
}

export { ActionBar, Cell, Description, DrilldownBlockContentWrapper, NestedLevel, Property, Row, Title, Value };

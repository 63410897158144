"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import deepEqual from 'fast-deep-equal';
import { toSnakeCase } from 'js-convert-case';
import { css } from '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { useNavigationContext } from './KeyboardNavigation/NavigationContext.mjs';
import DropdownOption from './Option.mjs';
import useDataBuilder from '../../DataBuilder/data-builder-context.mjs';
import useDataBuilderField from '../../DataBuilder/field-context.mjs';
import { useComboBox } from '../context/combobox-context.mjs';
import { useKeyboardBinding } from './KeyboardNavigation/useKeyboardBinding.mjs';

function OptionsList({
  onOptionSelect
}) {
  const { onAddVariable } = useDataBuilder();
  const { field } = useDataBuilderField();
  const {
    isOptionSelected,
    canUseParentInputSearchValue,
    drillDown,
    optionsPayload,
    drillUp
  } = useComboBoxDropdownContext();
  const { value: parentInputValue } = useComboBox();
  const [result, optionsError, optionsState] = optionsPayload;
  const { options = [], fallbackOptions = [] } = result || {};
  useKeyboardBinding({ options, fallbackOptions, onEnter: handleOptionSelect });
  function handleOptionSelect(option) {
    if (option === "drill-up") {
      drillUp();
      return;
    }
    if (option.addVariable) {
      const objectLocator = option.addVariable;
      const variableLocator = `${objectLocator}.${field.key || toSnakeCase(field.name) || toSnakeCase(field?.schema?.title)}`;
      onAddVariable?.(variableLocator, field.schema ?? {});
      onOptionSelect({
        $var: variableLocator
      });
    } else if (option.drillDownOptionFactory || option.childCategory && !option.value) {
      drillDown(option);
    } else {
      onOptionSelect(option.value);
    }
  }
  return /* @__PURE__ */ jsx(DropdownListState, { state: optionsState, error: optionsError, children: /* @__PURE__ */ jsx(
    DropdownListOptions,
    {
      searchTerm: canUseParentInputSearchValue ? parentInputValue : void 0,
      options,
      fallbackOptions,
      isOptionSelected,
      handleOptionSelect
    }
  ) });
}
function DropdownListState({
  state,
  error,
  children
}) {
  if (state === "rejected" || error) {
    return /* @__PURE__ */ jsx(
      DropdownUI.ErrorHeading,
      {
        text: error?.message || "Something went wrong"
      }
    );
  }
  if (state === "pending") {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
      /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
      /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {})
    ] });
  }
  return children;
}
function DropdownListOptions({
  options,
  fallbackOptions,
  isOptionSelected,
  handleOptionSelect,
  searchTerm
}) {
  const { currentItem } = useNavigationContext();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    !options.length && /* @__PURE__ */ jsx(
      DropdownUI.NoOptionsHeading,
      {
        text: searchTerm ? `No results found for: ${searchTerm}` : "No results found"
      }
    ),
    options.map((option, idx) => /* @__PURE__ */ jsx(
      DropdownOption,
      {
        option,
        selected: isOptionSelected(option),
        current: deepEqual(option, currentItem),
        onSelect: () => handleOptionSelect(option)
      },
      idx
    )),
    fallbackOptions.length > 0 && /* @__PURE__ */ jsx("hr", { className: css({ my: 2 }) }),
    fallbackOptions.map((option, idx) => /* @__PURE__ */ jsx(
      DropdownOption,
      {
        option,
        selected: isOptionSelected(option),
        current: deepEqual(option, currentItem),
        onSelect: () => handleOptionSelect(option)
      },
      idx
    ))
  ] });
}

export { OptionsList };

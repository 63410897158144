import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'

const html = `
<html lang="en">
  <head>
    <!-- Use latest version -->
    <script
      src="https://cdn.jsdelivr.net/npm/@integration-app/sdk/dist/bundle.js"
    ></script>

    <!-- Use specific version -->
    <script
      src="https://cdn.jsdelivr.net/npm/@integration-app/sdk@1.8.0/dist/bundle.js"
    ></script>

    <script>
      console.log(IntegrationAppClient)
    </script>
  </head>
  <body></body>
</html>
`

export function InstallSdkCdnCodeExample() {
  return <ExampleCodeBlock customCodes={{ html }} />
}

import { useState } from 'react'
import {
  makeDataField,
  DataBuilderForm,
  SvgIcon,
  SvgIconType,
  SimpleInput,
  DataInput,
} from '@integration-app/react'
import { schemaWithTitle } from '@integration-app/sdk'
import { toHeaderCase } from 'js-convert-case'

import { CodeBlockElement } from 'components/CodeBlock/elements'
import {
  CodeParamType,
  ParameterSpec,
  ParametersSpec,
} from 'components/Docs/ExampleCodeBlock'
import { ActionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/ActionComboboxSelect'
import { BooleanComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/BooleanComboboxSelect'
import { DataSourceKeyComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/DataSourceKeyComboboxSelect'
import { FieldMappingComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/FieldMappingComboboxSelect'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import { IntegrationWithConnectionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationWithConnectionComboboxSelect'
import { UdmComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/UdmComboboxSelect'
import useDocs from 'routes/Docs/components/docs-context'
import { Button } from 'ui-kit/button'
import clsx from 'utils/clsx'

import { ConnectionIdComboboxSelect } from '../../IntegrationElements/ComboboxSelects/ConnectionIdComboboxSelect'
import { FlowInstanceComboboxSelect } from '../../IntegrationElements/ComboboxSelects/FlowInstanceComboboxSelect'
import { FlowKeyComboboxSelect } from '../../IntegrationElements/ComboboxSelects/FlowKeyComboboxSelect'

export function CodeParams({
  disabled,
  expandedByDefault = false,
  parametersSpec,
}: {
  disabled?: boolean
  expandedByDefault?: boolean
  parametersSpec: ParametersSpec
}) {
  const { isVariableParameter } = useDocs()
  const [showParameters, setShowParameters] = useState(expandedByDefault)

  const editableParameters = Object.keys(parametersSpec ?? {})
    .filter((key) => isVariableParameter(key))
    .filter((key) => !(parametersSpec?.[key] as ParameterSpec)?.readOnly)

  const amountOfParameters = editableParameters.length

  const toggleParameters = () => {
    setShowParameters(!showParameters)
  }

  if (!amountOfParameters) {
    return null
  }

  if (disabled) {
    return null
  }

  return (
    <CodeBlockElement.Section>
      {amountOfParameters > 1 && !expandedByDefault && (
        <Button
          variant={'subtle'}
          size={'sm'}
          onClick={toggleParameters}
          className={clsx(
            'w-44 justify-center -mt-[1px]',
            showParameters && 'mb-2.5',
          )}
        >
          {showParameters ? 'Hide Parameters' : 'Edit Parameters'}

          <SvgIcon
            type={SvgIconType.Settings}
            className={'ml-2 -mr-2 w-5 h-5'}
          ></SvgIcon>
        </Button>
      )}
      {(amountOfParameters <= 1 || showParameters) && (
        <ParamsList parametersSpec={parametersSpec} />
      )}
    </CodeBlockElement.Section>
  )
}

function ParamsList({ parametersSpec }: { parametersSpec: ParametersSpec }) {
  const { parameters, patchParameters, isVariableParameter } = useDocs()

  const parametersToRender: [string, CodeParamType | ParameterSpec][] =
    Object.entries(parametersSpec ?? {}).filter(([name]) =>
      isVariableParameter(name),
    )

  return (
    <div className={'grid grid-cols-1 gap-2 w-full'}>
      {parametersToRender.map(([name, parameterSpec]) =>
        isVariableParameter(name) ? (
          <RunParam
            key={name}
            name={name}
            spec={parameterSpec as ParameterSpec}
            type={
              typeof parameterSpec === 'object'
                ? parameterSpec?.type
                : parameterSpec
            }
            value={parameters?.[name]}
            onChange={(value) => patchParameters({ [name]: value })}
          />
        ) : null,
      )}
    </div>
  )
}

function RunParam({
  name,
  type,
  value: providedValue,
  spec,
  onChange,
}: {
  name: string
  type: CodeParamType
  value: any
  spec: ParameterSpec
  onChange: (value: any) => void
}) {
  let value = providedValue
  if (value === undefined) {
    value = spec?.default
  }

  switch (type) {
    case CodeParamType.String:
      return (
        <DataBuilderForm
          field={makeDataField({
            schema: schemaWithTitle(
              {
                type: 'string',
              },
              toHeaderCase(name),
            ),
            value,
          })}
          onChange={onChange}
        />
      )
    case CodeParamType.Boolean:
      return (
        <BooleanComboboxSelect
          name={name}
          value={value}
          onChange={onChange}
          width={'fit-content'}
        />
      )
    case CodeParamType.integrationKeyWithoutConnection:
      return (
        <IntegrationComboboxSelect
          width={56}
          value={value}
          onChange={onChange}
          name={'App'}
        />
      )
    case CodeParamType.IntegrationKey:
      return (
        <IntegrationWithConnectionComboboxSelect
          name={'App'}
          integrationKey={value}
          onIntegrationSelect={onChange}
        />
      )
    case CodeParamType.ConnectionId:
      return (
        <ConnectionIdComboboxSelect
          value={value}
          onChange={onChange}
          width={'fit-content'}
          name={'Connection'}
        />
      )
    case CodeParamType.FieldMappingKey:
      return (
        <FieldMappingComboboxSelect
          value={value}
          onChange={onChange}
          width={'fit-content'}
          name={'Field Mapping'}
        />
      )
    case CodeParamType.FlowKey:
      return (
        <FlowKeyComboboxSelect
          value={value}
          onChange={onChange}
          width={'fit-content'}
          name={'Flow'}
        />
      )
    case CodeParamType.FlowInstanceId:
      return (
        <FlowInstanceComboboxSelect
          value={value}
          onChange={onChange}
          width={'fit-content'}
          name={'Flow Instance'}
        />
      )
    case CodeParamType.ActionKey:
      return (
        <ActionComboboxSelect
          value={value}
          onChange={onChange}
          width={'fit-content'}
          name={'Action'}
        />
      )
    case CodeParamType.DataSourceKey:
      return (
        <DataSourceKeyComboboxSelect
          value={value}
          onChange={onChange}
          width={'fit-content'}
          name={'Data Source'}
        />
      )
    case CodeParamType.UDM:
      return (
        <UdmComboboxSelect
          name={'UDM'}
          value={value}
          onChange={onChange}
          width={'fit-content'}
          positioning={{ sameWidth: false }}
        />
      )
    case CodeParamType.Object:
      // Objects can only be set from code, not by user (for now)
      return null
    case CodeParamType.DataSchema:
      return spec?.schema ? (
        <div>
          <DataInput
            schema={schemaWithTitle(
              spec?.schema,
              spec?.title ?? toHeaderCase(name),
            )}
            value={value}
            onChange={onChange}
          />
        </div>
      ) : null
    default:
      return <SimpleInput label={name} value={value} onChange={onChange} />
  }
}

"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { $getRoot, $isParagraphNode } from 'lexical';
import { $isEditorTagNode } from '../nodes/EditorTagNode.mjs';
import { transformTplToValue } from './common.mjs';
import { getTagString } from './tag-template-composition.mjs';

function transformEditorSchemaToValue(editorState) {
  const transformed = {
    template: "",
    values: {}
  };
  editorState.read(() => {
    const root = $getRoot();
    const paragraphs = [];
    for (const node of root.getChildren()) {
      if ($isParagraphNode(node)) {
        const paragraphContent = processParagraphNode(node);
        paragraphs.push(paragraphContent.join(""));
        transformed.values = {
          ...transformed.values,
          ...extractTagValues(node)
        };
      }
    }
    transformed.template = paragraphs.join("\n");
  });
  if (Object.keys(transformed.values).length === 0) {
    return transformed.template || "";
  }
  return transformTplToValue(transformed.template, transformed.values);
}
function processParagraphNode(node) {
  const contentParts = [];
  for (const child of node.getChildren()) {
    if ($isEditorTagNode(child)) {
      const id = child.id;
      contentParts.push(getTagString(id));
    } else {
      contentParts.push(child.getTextContent());
    }
  }
  return contentParts;
}
function extractTagValues(node) {
  const values = {};
  for (const child of node.getChildren()) {
    if ($isEditorTagNode(child)) {
      values[child.id] = child.value;
    }
  }
  return values;
}

export { transformEditorSchemaToValue };

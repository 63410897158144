import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const inputFn = /* @__PURE__ */ createRecipe('input', {
  "size": "md"
}, [
  {
    "size": "dense",
    "searchIcon": true,
    "css": {
      "paddingInlineStart": "9",
      "backgroundSize": "1rem",
      "backgroundPosition": "0.7rem center"
    }
  },
  {
    "size": "xs",
    "searchIcon": true,
    "css": {
      "paddingInlineStart": "9",
      "backgroundSize": "1rem",
      "backgroundPosition": "0.7rem center"
    }
  },
  {
    "size": "sm",
    "searchIcon": true,
    "css": {
      "paddingInlineStart": "9",
      "backgroundSize": "1rem",
      "backgroundPosition": "0.7rem center"
    }
  },
  {
    "size": "md",
    "searchIcon": true,
    "css": {
      "paddingInlineStart": "9",
      "backgroundSize": "1.25rem",
      "backgroundPosition": "0.625rem center"
    }
  },
  {
    "size": "lg",
    "searchIcon": true,
    "css": {
      "paddingInlineStart": "9",
      "backgroundSize": "1.25rem",
      "backgroundPosition": "0.625rem center"
    }
  },
  {
    "size": "xl",
    "searchIcon": true,
    "css": {
      "paddingInlineStart": "9",
      "backgroundSize": "1.25rem",
      "backgroundPosition": "0.625rem center"
    }
  }
])

const inputVariantMap = {
  "variant": [
    "outline",
    "subtle",
    "ghost"
  ],
  "size": [
    "2xs",
    "md",
    "lg",
    "xl",
    "2xl",
    "dense",
    "xs",
    "sm"
  ],
  "searchIcon": [
    "true"
  ]
}

const inputVariantKeys = Object.keys(inputVariantMap)

export const input = /* @__PURE__ */ Object.assign(memo(inputFn.recipeFn), {
  __recipe__: true,
  __name__: 'input',
  __getCompoundVariantCss__: inputFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: inputVariantKeys,
  variantMap: inputVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, inputVariantKeys)
  },
  getVariantProps: inputFn.getVariantProps,
})
"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.9.1
 */
'use client';

import { jsx, Fragment } from 'react/jsx-runtime';
import { useRef } from 'react';
import { useIsTextTruncated } from '../../helpers/hooks/useIsTextTruncated.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../styled-system/jsx/box.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import { Tooltip } from '../Tooltip/index.mjs';

function Truncate({
  children,
  endLetters = 5,
  className,
  id,
  placement,
  offset,
  usePortal,
  tooltipBuilder = (children2) => children2,
  ...rest
}) {
  const overflowContainer = useRef(null);
  const isTruncated = useIsTextTruncated(overflowContainer);
  if (typeof children !== "string" || children.length < endLetters) {
    return className ? /* @__PURE__ */ jsx("span", { className, id, ...rest, children }) : /* @__PURE__ */ jsx(Fragment, { children });
  }
  return /* @__PURE__ */ jsx(
    Tooltip,
    {
      disabled: !isTruncated,
      tooltip: tooltipBuilder(children),
      type: "dropdown",
      withArrow: false,
      placement,
      offset,
      usePortal,
      id,
      children: /* @__PURE__ */ jsx(
        Box,
        {
          ref: overflowContainer,
          className,
          truncate: true,
          ...rest,
          children
        }
      )
    }
  );
}

export { Truncate };
